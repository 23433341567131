import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import f1 from '../images/dressPage/frock kg-compress/KGF  5 (1)_1_11zon.jpg'
import f2 from '../images/dressPage/frock kg-compress/KGF 1_2_11zon.jpg'
import f3 from '../images/dressPage/frock kg-compress/KGF 2_3_11zon.jpg'
import f4 from '../images/dressPage/frock kg-compress/KGF 3_4_11zon.jpg'
import f5 from '../images/dressPage/frock kg-compress/KGF 4_5_11zon.jpg'
import f6 from '../images/dressPage/frock kg-compress/KGF 6_6_11zon.jpg'
import f7 from '../images/dressPage/frock kg-compress/KGF 7_1_11zon.jpg'
import f8 from '../images/dressPage/frock kg-compress/KGF 8_2_11zon.jpg'
import f9 from '../images/dressPage/frock kg-compress/KGF 9_3_11zon.jpg'

import flp1 from '../images/dressPage/frock lp-compress/LPF 1_1_11zon.jpg'
import flp2 from '../images/dressPage/frock lp-compress/LPF 2_2_11zon.jpg'
import flp3 from '../images/dressPage/frock lp-compress/LPF 3_3_11zon.jpg'
import flp4 from '../images/dressPage/frock lp-compress/LPF 4_4_11zon.jpg'
import flp5 from '../images/dressPage/frock lp-compress/LPF 5_5_11zon.jpg'

import fusion1 from '../images/dressPage/fusion-compress/F 1 (2)_11zon.jpg'

import jlp1 from '../images/dressPage/jubba lp-compress/JLP 1_1_11zon.jpg'
import jlp2 from '../images/dressPage/jubba lp-compress/JLP 2_2_11zon.jpg'
import jlp3 from '../images/dressPage/jubba lp-compress/JLP 3_3_11zon.jpg'
import jlp5 from '../images/dressPage/jubba lp-compress/JLP 5_4_11zon.jpg'
import jlp6 from '../images/dressPage/jubba lp-compress/JLP 6_5_11zon.jpg'
import jlp7 from '../images/dressPage/jubba lp-compress/JLP 7_6_11zon.jpg'

import p1 from '../images/dressPage/lp pant-compress/P 10_4_11zon.jpg'
import p2 from '../images/dressPage/lp pant-compress/P 11_5_11zon.jpg'
import p3 from '../images/dressPage/lp pant-compress/P 12_6_11zon.jpg'
import p4 from '../images/dressPage/lp pant-compress/P 8 (1)_1_11zon.jpg'
import p5 from '../images/dressPage/lp pant-compress/P 8 (2)_2_11zon.jpg'
import p6 from '../images/dressPage/lp pant-compress/P 9_3_11zon.jpg'

import mm1 from '../images/dressPage/mime-compress/MM 1_1_11zon.jpg'
import mm2 from '../images/dressPage/mime-compress/MM 2_2_11zon.jpg'
import mm3 from '../images/dressPage/mime-compress/MM 3_3_11zon.jpg'
import mm4 from '../images/dressPage/mime-compress/MM 4_4_11zon.jpg'

import ptlp1 from '../images/dressPage/pant type lp-compress/LPAR 1_1_11zon.jpg'
import ptlp2 from '../images/dressPage/pant type lp-compress/LPC 1 (2)_2_11zon.jpg'
import ptlp3 from '../images/dressPage/pant type lp-compress/LPC 2_3_11zon.jpg'
import ptlp4 from '../images/dressPage/pant type lp-compress/LPP 1_4_11zon.jpg'
import ptlp5 from '../images/dressPage/pant type lp-compress/LPP 2_5_11zon.jpg'
import ptlp6 from '../images/dressPage/pant type lp-compress/LPP 3_6_11zon.jpg'

import gs1 from '../images/dressPage/shall-compress/GS 1_1_11zon.jpg'
import gs2 from '../images/dressPage/shall-compress/GS 2_2_11zon.jpg'
import gs3 from '../images/dressPage/shall-compress/GS 3_3_11zon.jpg'
import gs4 from '../images/dressPage/shall-compress/GS 4_4_11zon.jpg'
import gs5 from '../images/dressPage/shall-compress/GS 5_1_11zon.jpg'
import gs6 from '../images/dressPage/shall-compress/GS 6_2_11zon.jpg'

import ksh1 from '../images/dressPage/shervani kg-compress/KSH 1_1_11zon.jpg'
import ksh2 from '../images/dressPage/shervani kg-compress/KSH 2_2_11zon.jpg'
import ksh3 from '../images/dressPage/shervani kg-compress/KSH 3_3_11zon.jpg'
import ksh4 from '../images/dressPage/shervani kg-compress/KSH 4_4_11zon.jpg'
import ksh5 from '../images/dressPage/shervani kg-compress/KSH 5_5_11zon.jpg'
import ksh6 from '../images/dressPage/shervani kg-compress/KSH 6_6_11zon.jpg'
import ksh7 from '../images/dressPage/shervani kg-compress/KSH 7_7_11zon.jpg'

import lpt1 from '../images/dressPage/thorth mund-compress/LPT 1_1_11zon.jpg'
import lpt2 from '../images/dressPage/thorth mund-compress/LPT 2_2_11zon.jpg'
import lpt3 from '../images/dressPage/thorth mund-compress/LPT 4 (1)_3_11zon.jpg'
import lpt4 from '../images/dressPage/thorth mund-compress/LPT 4 (2)_4_11zon.jpg'
import lpt5 from '../images/dressPage/thorth mund-compress/LPT 4 (3)_1_11zon.jpg'
import lpt6 from '../images/dressPage/thorth mund-compress/LPT 4 (4)_2_11zon.jpg'
import lpt7 from '../images/dressPage/thorth mund-compress/LPT 4 (5)_1_11zon.jpg'
import lpt8 from '../images/dressPage/thorth mund-compress/LPT 4 (6)_2_11zon.jpg'
import lpt9 from '../images/dressPage/thorth mund-compress/LPT 4 (7)_3_11zon.jpg'
import lpt10 from '../images/dressPage/thorth mund-compress/LPT 4 (8)_4_11zon.jpg'

import a1 from '../images/dressPage/Adivasi/ADI 1_11zon.jpg'

import a2 from '../images/dressPage/Anaarkali/ANI 1_11zon.jpg'



import bc1 from '../images/dressPage/Boys Coat/BK 1_1_11zon.jpg'
import bc2 from '../images/dressPage/Boys Coat/BK 3_2_11zon.jpg'
import bc3 from '../images/dressPage/Boys Coat/BK 4_3_11zon.jpg'
import bc4 from '../images/dressPage/Boys Coat/BK 5_4_11zon.jpg'
import bc5 from '../images/dressPage/Boys Coat/BK 6_5_11zon.jpg'
import bc6 from '../images/dressPage/Boys Coat/BK 7_6_11zon.jpg'
import bc7 from '../images/dressPage/Boys Coat/BK 8_1_11zon.jpg'
import bc8 from '../images/dressPage/Boys Coat/BK 9_2_11zon.jpg'
import bc9 from '../images/dressPage/Boys Coat/BK 10_3_11zon.jpg'
import bc10 from '../images/dressPage/Boys Coat/BK 11_4_11zon.jpg'
import bc11 from '../images/dressPage/Boys Coat/BK 12_5_11zon.jpg'
import bc12 from '../images/dressPage/Boys Coat/BK 13_6_11zon.jpg'

import ch1 from '../images/dressPage/Choly Hs/HSCH 1_1_11zon.jpg'
import ch2 from '../images/dressPage/Choly Hs/HSCH 2_2_11zon.jpg'
import ch3 from '../images/dressPage/Choly Hs/HSCH 3_3_11zon.jpg'
import ch4 from '../images/dressPage/Choly Hs/HSCH 4_4_11zon.jpg'


import chkg1 from '../images/dressPage/Choly Kg/KGCH 1_1_11zon.jpg'
import chkg2 from '../images/dressPage/Choly Kg/KGCH 2_2_11zon.jpg'
import chkg3 from '../images/dressPage/Choly Kg/KGCH 3_3_11zon.jpg'
import chkg4 from '../images/dressPage/Choly Kg/KGCH 5_4_11zon.jpg'
import chkg5 from '../images/dressPage/Choly Kg/KGCH 6_5_11zon.jpg'
import chkg6 from '../images/dressPage/Choly Kg/KGCH 7_6_11zon.jpg'

import chlp1 from '../images/dressPage/Choly Lp/LPCH 1_1_11zon.jpg'
import chlp2 from '../images/dressPage/Choly Lp/LPCH 2_2_11zon.jpg'
import chlp3 from '../images/dressPage/Choly Lp/LPCH 3_3_11zon.jpg'
import chlp4 from '../images/dressPage/Choly Lp/LPCH 6 (2)_4_11zon.jpg'
import chlp5 from '../images/dressPage/Choly Lp/LPCH 7_5_11zon.jpg'
import chlp6 from '../images/dressPage/Choly Lp/LPCH 9_6_11zon.jpg'

import chup1 from '../images/dressPage/Choly Up/UPCH 1_1_11zon.jpg'
import chup2 from '../images/dressPage/Choly Up/UPCH 2_2_11zon.jpg'
import chup3 from '../images/dressPage/Choly Up/UPCH 3_3_11zon.jpg'


import Navbar2 from '../components/Navbar2'
import Footer2 from '../components/Footer2'

function DressPage() {

  const [showMore, setShowMore] = useState(false);

  const [selectedType, setSelectedType] = useState('frockkg');
  const handleTypeClick = (type) => {
    setSelectedType(type);
  };
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const home = () => {
    navigate('/');
  };


  return (
    <div className='min-h-screen flex flex-col'>
      <Navbar2 />
      <div className='min-h-screen flex flex-col w-full h-auto lg:px-12 px-2  py-8 gap-8 items-start justify-start'>
        <div className=''><span className='text-[#828282] text-lg font-[500] font-[display2] cursor-pointer' onClick={home}>Home</span> {">"}  <span className='text-lg font-[500] font-[display2]'>Dance Dress</span></div>
        <div className='w-full flex flex-row'>
          {/* <div className='w-[10%]  flex justify-start '>
                <div className='w-[60%] h-[33px] border-2 border-[#9D9494] rounded-[5px] flex flex-row justify-center items-center gap-2'>
                   <img src={filter} alt='filter'/>
                   <span className='text-lg font-[500] font-[display]'>Filter</span>
                </div>
            </div> */}
          {/* border-2 border-[#C30000] p-2 rounded-[20px] */}
          <div className='w-[100%] justify-center items-center grid lg:grid-cols-5 grid-cols-2 lg:gap-6 gap-3'>
            {/* First 5 items */}
            <span
              className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'frockkg' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`}
              onClick={() => handleTypeClick('frockkg')}
            >
              FROCK KG
            </span>
            <span
              className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'frocklp' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`}
              onClick={() => handleTypeClick('frocklp')}
            >
              FROCK LP
            </span>
            <span
              className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'fusion' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`}
              onClick={() => handleTypeClick('fusion')}
            >
              FUSION
            </span>
            <span
              className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'jubbalp' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`}
              onClick={() => handleTypeClick('jubbalp')}
            >
              JUBBA LP
            </span>
            <span
              className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'lppant' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`}
              onClick={() => handleTypeClick('lppant')}
            >
              LP PANT
            </span>

            {/* Conditionally render the rest */}
            {showMore && (
              <>
                <span
                  className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'mime' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`}
                  onClick={() => handleTypeClick('mime')}
                >
                  MIME
                </span>
                <span
                  className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'panttypelp' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`}
                  onClick={() => handleTypeClick('panttypelp')}
                >
                  PANT TYPE LP
                </span>
                <span
                  className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'shawl' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`}
                  onClick={() => handleTypeClick('shawl')}
                >
                  SHAWL
                </span>
                <span
                  className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'shervanikg' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`}
                  onClick={() => handleTypeClick('shervanikg')}
                >
                  SHERVANI KG
                </span>
                <span
                  className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'thorthmund' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`}
                  onClick={() => handleTypeClick('thorthmund')}
                >
                  THORTH MUND
                </span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'adivasi' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('adivasi')}>ADIVASI</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'anarkali' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('anarkali')}>ANARKALI</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'boyscoat' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('boyscoat')}>BOYS COAT</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'cholyhs' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('cholyhs')}>CHOLY HS</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'cholykg' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('cholykg')}>CHOLY KG</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'cholylp' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('cholylp')}>CHOLY LP</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'cholyup' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('cholyup')}>CHOLY UP</span>

              </>
            )}

            {/* Toggle button */}
            <button
              onClick={() => setShowMore(!showMore)}
              className="text-[#C30000] font-[500] mt-2"
            >
              {showMore ? 'See Less' : 'See More'}
            </button>
          </div>
        </div>


        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'frockkg' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGF 5</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGF 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGF 2</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGF 3</div>
          </div>



          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGF 4</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGF 6</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGF 7</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGF 8</div>
          </div>

          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGF 9</div>
          </div>
        </div>



        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'frocklp' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={flp1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPF 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={flp2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPF 2</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={flp3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPF 3</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={flp4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPF 4</div>
          </div>



          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={flp5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPF 5</div>
          </div>

        </div>



        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'fusion' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={fusion1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>F1</div>
          </div>


        </div>




        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'jubbalp' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>JLP 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>JLP 2</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>JLP 3</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>JLP 5</div>
          </div>



          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>JLP 6</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>JLP 7</div>
          </div>

        </div>


        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'lppant' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>P10</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>P11</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>P12</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>P8 1</div>
          </div>



          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>P8 2</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>P9</div>
          </div>

        </div>


        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'mime' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mm1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MM 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mm2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MM 2</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mm3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MM 3</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mm4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MM 4</div>
          </div>

        </div>

        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'panttypelp' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ptlp1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPAR 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ptlp2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPC 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ptlp3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPC 2</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ptlp4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPP 1</div>
          </div>



          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ptlp5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPP 2</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ptlp6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPP 3</div>
          </div>

        </div>




        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'shawl' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={gs1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>GS 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={gs2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>GS 2</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={gs3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>GS 3</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={gs4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>GS 4</div>
          </div>



          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={gs5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>GS 5</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={gs6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>GS 6</div>
          </div>

        </div>





        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'shervanikg' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ksh1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KSH 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ksh2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KSH 2</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ksh3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KSH 3</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ksh4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KSH 4</div>
          </div>



          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ksh5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KSH 5</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ksh6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KSH 6</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ksh7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KSH 7</div>
          </div>

        </div>





        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'thorthmund' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPT 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPT 2</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPT4 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPT4 2</div>
          </div>



          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPT4 3</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPT4 4</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPT4 5</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPT4 6</div>
          </div>


          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPT4 7</div>
          </div>  <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPT4 8</div>
          </div>

        </div>



        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'adivasi' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={a1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>ADI 1</div>
          </div>
        </div>


        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'anarkali' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={a2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>ANI 1</div>
          </div>
        </div>


        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'boyscoat' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={bc1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BK 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={bc2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BK 3</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={bc3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BK 4</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={bc4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BK 5</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={bc5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BK 6</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={bc6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BK 7</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={bc7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BK 8</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={bc8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BK 9</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={bc9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BK 10</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={bc10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BK 11</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={bc11} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BK 12</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={bc12} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BK 13</div>
          </div>
        </div>


        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'cholyhs' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ch1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSCH 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ch2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSCH 2</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ch3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSCH 3</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ch4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSCH 4</div>
          </div>
        </div>


        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'cholykg' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chkg1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGCH 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chkg2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGCH 2</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chkg3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGCH 3</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chkg4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGCH 5</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chkg5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGCH 6</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chkg6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGCH 7</div>
          </div>
        </div>




        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'cholylp' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chlp1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPCH 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chlp2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPCH 2</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chlp3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPCH 3</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chlp4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPCH 6</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chlp5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPCH 7</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chlp6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPCH 9</div>
          </div>
        </div>



        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'cholyup' ? 'grid' : 'none' }}>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chup1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>UPCH 1</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chup2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>UPCH 2</div>
          </div>
          <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={chup3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>UPCH 3</div>
          </div>
        </div>

      </div>
      <Footer2 />
    </div>
  )
}

export default DressPage
