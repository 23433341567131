import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import bn1 from '../images/kuchupudi-compress/KUCHIPPUDI 38F (3)_1_11zon.jpg'
import bn2 from '../images/kuchupudi-compress/KUCHUPPUDI  34A (3)_2_11zon.jpg'
import bn3 from '../images/kuchupudi-compress/KUCHUPPUDI  37.5 A (2)_3_11zon.jpg'
import bn4 from '../images/kuchupudi-compress/KUCHUPPUDI  38H (3)_4_11zon.jpg'
import bn5 from '../images/kuchupudi-compress/KUCHUPPUDI 34B (2)_5_11zon.jpg'
import bn6 from '../images/kuchupudi-compress/KUCHUPPUDI 34D (2)_6_11zon.jpg'
import bn7 from '../images/kuchupudi-compress/KUCHUPPUDI 34E (2)_1_11zon.jpg'
import bn8 from '../images/kuchupudi-compress/KUCHUPPUDI 36A (2)_2_11zon.jpg'
import bn9 from '../images/kuchupudi-compress/KUCHUPPUDI 36C (2)_3_11zon.jpg'
import bn10 from '../images/kuchupudi-compress/KUCHUPPUDI 36D (2)_4_11zon.jpg'
import bn11 from '../images/kuchupudi-compress/KUCHUPPUDI 36E (2)_5_11zon.jpg'
import bn12 from '../images/kuchupudi-compress/KUCHUPPUDI 36F (2)_6_11zon.jpg'
import bn13 from '../images/kuchupudi-compress/KUCHUPPUDI 36O (1)_1_11zon.jpg'
import bn14 from '../images/kuchupudi-compress/KUCHUPPUDI 38B (2)_2_11zon.jpg'
import bn15 from '../images/kuchupudi-compress/KUCHUPPUDI 38C (2)_3_11zon.jpg'
import bn16 from '../images/kuchupudi-compress/KUCHUPPUDI 38D (2)_4_11zon.jpg'
import bn17 from '../images/kuchupudi-compress/KUCHUPPUDI 38E (3)_5_11zon.jpg'
import bn18 from '../images/kuchupudi-compress/KUCHUPPUDI 38G (2)_6_11zon.jpg'
import bn19 from '../images/kuchupudi-compress/KUCHUPPUDI 38M (2)_1_11zon.jpg'
import bn20 from '../images/kuchupudi-compress/KUCHUPPUDI 38Q (3)_2_11zon.jpg'
import bn21 from '../images/kuchupudi-compress/KUCHUPPUDI 39A (2)_3_11zon.jpg'
import bn22 from '../images/kuchupudi-compress/KUCHUPPUDI 39B (2)_4_11zon.jpg'
import bn23 from '../images/kuchupudi-compress/KUCHUPPUDI 39C (3)_5_11zon.jpg'
import bn24 from '../images/kuchupudi-compress/KUCHUPPUDI 40A (2)_6_11zon.jpg'
import Footer2 from '../components/Footer2'
import Navbar2 from '../components/Navbar2'


function Kuchupudi() {


    
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const dancecostume = () => {
        navigate('/dancecostume');
    };


    return (
        <div className='min-h-screen flex flex-col'>
            <Navbar2 />
            <div className='flex flex-col w-full h-auto lg:px-12 px-2  py-8 gap-8 items-start justify-start'>
                <div className=''><span className='text-[#828282] text-lg font-[500] font-[display2] cursor-pointer' onClick={dancecostume}>Dance Costume</span> {">"}  <span className='text-lg font-[500] font-[display2]'>Kuchuppudi</span></div>
         


                <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' >
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 38F</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI  34A</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI  37.5 A</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI  38H</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 34B</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 34D</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 34E</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 36A</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 36C</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 36D</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn11} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 36E</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn12} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 36F</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn13} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 36O</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn14} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 38B</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn15} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 38C</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn16} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 38D </div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn17} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 38E</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn18} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 38G</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn19} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 38M</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn20} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 38Q</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn21} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 39A</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn22} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 39B</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn23} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 39C</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn24} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KUCHUPPUDI 40A</div>
                    </div>
                 
               
                </div>

            </div>
            <Footer2 />
        </div>
    )
}

export default Kuchupudi
