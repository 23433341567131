import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import main from '../images/home/main.png'
import dress2 from '../images/home/Dress.png'
import checkmark from '../images/home/Checkmark.png'
import dancer from '../images/home/dancer.png'
import dance from '../images/home/dance.png'
import returnproduct from '../images/home/return.png'
import bangle from '../images/home/Bangle.png'
import kebaya from '../images/home/Kebaya.png'
import balance from '../images/home/Balance.png'
import girl from '../images/home/girl.png'
import Footer from '../components/Footer'
import booknow from '../images/home/book now.png'
import mainmob from '../images/home/mainmob.png'

function Home() {

  const aboutSectionRef = useRef(null);
  const productSectionRef = useRef(null);
  const productExploreRef = useRef(null);



  const scrollToAbout = () => {
    aboutSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToProduct = () => {
    productSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToExplore = () => {
    productExploreRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  const navigate = useNavigate()

  const dancecostume = () => {
    navigate('/dancecostume');
  };

  const dress = () => {
    navigate('/dress');
  };


  const properties = () => {
    navigate('/properties');
  };

  const ornamentspage = () => {
    navigate('/ornaments');
  };

  const contact = () => {
    navigate('/contact');
  };

  return (
    <div className='min-h-screen flex flex-col'>
       <Navbar scrollToAbout={scrollToAbout} scrollToProduct={scrollToProduct} />
      <div className='h-[940px] w-full hidden lg:flex relative'>
        <img src={main} alt='main' style={{ width: '100%', height: "100%" }} />
        <div className='absolute inset-0 flex flex-row w-[100%] h-[100%] '>
          <div className='w-[30%] px-12 flex flex-col justify-center items-start text-start gap-4'>
            <span className='text-6xl font-[700] font-[display] text-[white]'>Elegant Dance Dress Rentals.</span>
            <span className='text-xl font-[200] font-[display] text-[white]'>Affordable Dance Costume Rentals for All Your Performance Needs</span>
            <div className='h-[51px] w-[50%] bg-[white] rounded-[7px] flex justify-center items-center text-xl font-[700] font-[display2] cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={scrollToExplore}>Explore now</div>
          </div>
          <div className='w-[65%] h-full '></div>
        </div>
      </div>
      <div className='h-[844px] w-full lg:hidden flex relative'>
      <img src={mainmob} alt='main' style={{ width: '100%', height: "100%" }} />
         
          <div className='w-[100%] h-full   absolute'>
            <div className='h-[50%] w-full '></div>
            <div className='h-[50%] w-full  px-4 flex flex-col justify-center gap-4 items-start text-start '>
            <span className='text-4xl font-[700] font-[display] text-[white]'>Elegant Dance Dress Rentals.</span>
            <span className='text-lg font-[200] font-[display] text-[white]'>Affordable Dance Costume Rentals for All Your Performance Needs</span>
            <div className='h-[51px] w-[60%] bg-[white] rounded-[7px] flex justify-center items-center text-xl font-[700] font-[display] cursor-pointer' onClick={scrollToExplore}>Explore now</div>
            </div>
           
          </div>
         
        
      </div>

      <div className='w-full h-auto gap-12 flex flex-col justify-center items-center lg:px-12 px:4 py-12'>
        <div className='text-3xl font-[600] font-[display2]'>How Kairali Works</div>
        <div className='grid lg:grid-cols-4 grid-cols-1 w-full gap-5 h-auto '>
          <div className='flex flex-col justify-center items-center text-center gap-3  px-12'>
            <img src={dress2} alt='dress' />
            <span className='text-xl font-[700] font-[display2]'>Select a Style</span>
            <span className='text-base font-[400] font-[display2] text-[#7E7E7E]'>Choose your ideal look from our curated selection of designer clothing and accessories.</span>
          </div>
          <div className='flex flex-col justify-center items-center text-center gap-3  px-12'>
            <img src={checkmark} alt='dress' />
            <span className='text-xl font-[700] font-[display2]'>Book your Outfit</span>
            <span className='text-base font-[400] font-[display2] text-[#7E7E7E]'>Designed to enhance your performance with style and comfort.</span>
          </div>
          <div className='flex flex-col justify-center items-center text-center gap-3  px-12'>
            <img src={dancer} alt='dress' />
            <span className='text-xl font-[700] font-[display2]'>Show off</span>
            <span className='text-base font-[400] font-[display2] text-[#7E7E7E]'>Available for rent to make sure you steal the show with every move!</span>
          </div>
          <div className='flex flex-col justify-center items-center text-center gap-3  px-12'>
            <img src={returnproduct} alt='dress' />
            <span className='text-xl font-[700] font-[display2]'>Return It</span>
            <span className='text-base font-[400] font-[display2] text-[#7E7E7E]'>Simply pack the outfit, attach the prepaid return label, and drop it off at Kairali</span>
          </div>
        </div>
        <div className='w-full lg:flex hidden flex-row justify-center items-center'>
          <div className='h-[37px] w-[37px] rounded-[50%] bg-[black] flex justify-center items-center text-[white]'>1</div>
          <div className='w-[300px]'><hr/></div>
          <div className='h-[37px] w-[37px] rounded-[50%] bg-[black] flex justify-center items-center text-[white]'>2</div>
          <div className='w-[300px]'><hr/></div>
          <div className='h-[37px] w-[37px] rounded-[50%] bg-[black] flex justify-center items-center text-[white]'>3</div>
          <div className='w-[300px]'><hr/></div>
          <div className='h-[37px] w-[37px] rounded-[50%] bg-[black] flex justify-center items-center text-[white]'>4</div>
         
        </div>
      </div>
      <div className='w-full h-auto gap-12 flex flex-col justify-center items-center lg:px-12 px-4 py-12' ref={productSectionRef}>
         <div className='text-3xl font-[600] font-[display2]' ref={productExploreRef}>Explore Our Categories</div>
         <div className='grid lg:grid-cols-2 h-auto grid-cols-1 w-full gap-5 '>
              <div className='bg-[#FAFAFA]  rounded-[5px] flex flex-col px-2 py-2 items-start justify-start text-left gap-8 cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={dancecostume}>
                    <div className='flex flex-col gap-3'>
                      <div className='w-[100px] h-[100px] rounded-[50%] bg-[black] flex justify-center items-center'>
                          <img src={dance} alt='dance'/>
                      </div>
                      <span className='font-[600] font-[display2] text-2xl'>Dance Costume</span>
                    </div>
                    <span className='text-xl font-[500] font-[display2]'>Kairali Dance Collections offers the widest range of dance costumes for everyone, with options for both individual and bulk orders.</span>
              </div>
              <div className='bg-[#FAFAFA]  rounded-[5px] flex flex-col px-2 py-2 items-start justify-start text-left gap-8 cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={ornamentspage}>
                    <div className='flex flex-col gap-3'>
                      <div className='w-[100px] h-[100px] rounded-[50%] bg-[black] flex justify-center items-center'>
                          <img src={bangle} alt='dance'/>
                      </div>
                      <span className='font-[600] font-[display2] text-2xl'>Dance Ornaments</span>
                    </div>
                    <span className='text-xl font-[500] font-[display2]'>We provide a full selection of high-quality dance accessories, ornaments, and props at affordable prices.</span>
              </div>
              <div className='bg-[#FAFAFA]  rounded-[5px] flex flex-col px-2 py-2 items-start justify-start text-left gap-8 cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={dress}>
                    <div className='flex flex-col gap-3'>
                      <div className='w-[100px] h-[100px] rounded-[50%] bg-[black] flex justify-center items-center'>
                          <img src={kebaya} alt='dance'/>
                      </div>
                      <span className='font-[600] font-[display2] text-2xl'>Dance Dress</span>
                    </div>
                    <span className='text-xl font-[500] font-[display2]'>Kairali Dance Collections offers the widest range of dance Dress for everyone, We offer a variety of dance dresses.</span>
              </div>
              <div className='bg-[#FAFAFA]  rounded-[5px] flex flex-col px-2 py-2 items-start justify-start text-left gap-8 cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={properties}>
                    <div className='flex flex-col gap-3'>
                      <div className='w-[100px] h-[100px] rounded-[50%] bg-[black] flex justify-center items-center'>
                          <img src={balance} alt='dance'/>
                      </div>
                      <span className='font-[600] font-[display2] text-2xl'>Other Properties</span>
                    </div>
                    <span className='text-xl font-[500] font-[display2]'>Explore our wide range of dance props and accessories. Enhance your performance with high-quality items at great prices.</span>
              </div>
         </div>
      </div>
      <div className='w-full lg:h-[476px] h-[280px] bg-[#F4E7D4] flex flex-row'>
         <div className='lg:w-[30%] w-[40%] h-full lg:flex hidden'>
             <img src={girl} alt='girl' style={{width:'100%',height:'100%'}}/>
         </div>
         <div className='lg:w-[70%] w-[100%] h-full flex flex-col justify-center items-center gap-5'>
          <span className='lg:text-4xl text-2xl font-[700] font-[display2]'>DANCE COSTUME & ACCESSORIES</span>
          <span className='lg:text-2xl text-lg font-[400] font-[display2]'>Everything you need to dress up during the festivities..!</span>
          {/* <img src={booknow} alt='booknow' onClick={contact} className='cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' /> */}
         </div>
      </div>
      <div className='w-full h-auto bg-[#4D4B4C] flex flex-col items-center py-12 gap-8 lg:px-12 px-4' ref={aboutSectionRef}>
          <span className='lg:text-3xl text-xl font-[600] font-[display2] text-[white]'>About Us</span>
          <span className='lg:text-lg text-sm font-[400] font-[display2] text-[white]'>Welcome to Kairali Dance Collection, your
             premier destination for renting exquisite Indian dance costumes. We specialize in
              providing a diverse range of traditional <br/><br/> and contemporary dance attire for all your
               performance needs. Our mission is to make authentic, high-quality dance
                costumes accessible and affordable for dancers of all levels.<br/><br/> 
                Whether you need outfits for Bharatanatyam, Kathak, or folk performances, 
                our collection ensures you look and feel your best on stage. Experience the 
                elegance and cultural<br/><br/> richness of Indian dance with our carefully curated  rental selection.
         </span>
          
      </div>
      <Footer scrollToAbout={scrollToAbout} scrollToProduct={scrollToProduct} />
    </div>
  )
}

export default Home
