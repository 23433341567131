import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import bn1 from '../images/bharathanatyam-compress/BARATHANATYAM 26B (3)_1_11zon.jpg'
import bn2 from '../images/bharathanatyam-compress/BARATHANATYAM 30E (3)_2_11zon.jpg'
import bn3 from '../images/bharathanatyam-compress/BARATHANATYAM 40D (3)_3_11zon.jpg'
import bn4 from '../images/bharathanatyam-compress/BHARATHA NATYAM 36H (3)_4_11zon.jpg'
import bn5 from '../images/bharathanatyam-compress/BHARATHANATYAM  24A (2)_5_11zon.jpg'
import bn6 from '../images/bharathanatyam-compress/BHARATHANATYAM  24B (2)_6_11zon.jpg'
import bn7 from '../images/bharathanatyam-compress/BHARATHANATYAM  28A (2)_1_11zon.jpg'
import bn8 from '../images/bharathanatyam-compress/BHARATHANATYAM  28C (3)_2_11zon.jpg'
import bn9 from '../images/bharathanatyam-compress/BHARATHANATYAM  28D (2)_3_11zon.jpg'
import bn10 from '../images/bharathanatyam-compress/BHARATHANATYAM  30C (3)_4_11zon.jpg'
import bn11 from '../images/bharathanatyam-compress/BHARATHANATYAM  30F (1)_5_11zon.jpg'
import bn12 from '../images/bharathanatyam-compress/BHARATHANATYAM  30G (2)_6_11zon.jpg'
import bn13 from '../images/bharathanatyam-compress/BHARATHANATYAM  32B (2)_1_11zon.jpg'
import bn14 from '../images/bharathanatyam-compress/BHARATHANATYAM  32D (2)_2_11zon.jpg'
import bn15 from '../images/bharathanatyam-compress/BHARATHANATYAM  32E (2)_3_11zon.jpg'
import bn16 from '../images/bharathanatyam-compress/BHARATHANATYAM  32F (2)_4_11zon.jpg'
import bn17 from '../images/bharathanatyam-compress/BHARATHANATYAM  34A (2)_5_11zon.jpg'
import bn18 from '../images/bharathanatyam-compress/BHARATHANATYAM  34J (1)_6_11zon.jpg'
import bn19 from '../images/bharathanatyam-compress/BHARATHANATYAM  37.5 A (3)_1_11zon.jpg'
import bn20 from '../images/bharathanatyam-compress/BHARATHANATYAM  38G (2)_2_11zon.jpg'
import bn21 from '../images/bharathanatyam-compress/BHARATHANATYAM  38I (3)_3_11zon.jpg'
import bn22 from '../images/bharathanatyam-compress/BHARATHANATYAM 26A (2)_4_11zon.jpg'
import bn23 from '../images/bharathanatyam-compress/BHARATHANATYAM 26C (3)_5_11zon.jpg'
import bn24 from '../images/bharathanatyam-compress/BHARATHANATYAM 28B (3)_6_11zon.jpg'
import bn25 from '../images/bharathanatyam-compress/BHARATHANATYAM 28E (2)_1_11zon.jpg'
import bn26 from '../images/bharathanatyam-compress/BHARATHANATYAM 30 D (2)_2_11zon.jpg'
import bn27 from '../images/bharathanatyam-compress/BHARATHANATYAM 30A (2)_3_11zon.jpg'
import bn28 from '../images/bharathanatyam-compress/BHARATHANATYAM 32A (1)_4_11zon.jpg'
import bn29 from '../images/bharathanatyam-compress/BHARATHANATYAM 32C (1)_5_11zon.jpg'
import bn30 from '../images/bharathanatyam-compress/BHARATHANATYAM 32G (3)_6_11zon.jpg'
import bn31 from '../images/bharathanatyam-compress/BHARATHANATYAM 34B (2)_1_11zon.jpg'
import bn32 from '../images/bharathanatyam-compress/BHARATHANATYAM 34C (2)_2_11zon.jpg'
import bn33 from '../images/bharathanatyam-compress/BHARATHANATYAM 34D (3)_3_11zon.jpg'
import bn34 from '../images/bharathanatyam-compress/BHARATHANATYAM 34F (1)_4_11zon.jpg'
import bn35 from '../images/bharathanatyam-compress/BHARATHANATYAM 34H (3)_5_11zon.jpg'
import bn36 from '../images/bharathanatyam-compress/BHARATHANATYAM 34I (3)_6_11zon.jpg' 
import bn37 from '../images/bharathanatyam-compress/BHARATHANATYAM 36A (3)_1_11zon.jpg'
import bn38 from '../images/bharathanatyam-compress/BHARATHANATYAM 36B (2)_2_11zon.jpg'
import bn39 from '../images/bharathanatyam-compress/BHARATHANATYAM 36C (2)_3_11zon.jpg'
import bn40 from '../images/bharathanatyam-compress/BHARATHANATYAM 36D (2)_4_11zon.jpg'
import bn41 from '../images/bharathanatyam-compress/BHARATHANATYAM 36E (3)_5_11zon.jpg'
import bn42 from '../images/bharathanatyam-compress/BHARATHANATYAM 36F (3)_6_11zon.jpg'
import bn43 from '../images/bharathanatyam-compress/BHARATHANATYAM 36G (3)_1_11zon.jpg'
import bn44 from '../images/bharathanatyam-compress/BHARATHANATYAM 36I (2)_2_11zon.jpg'
import bn45 from '../images/bharathanatyam-compress/BHARATHANATYAM 36J (2)_3_11zon.jpg'
import bn46 from '../images/bharathanatyam-compress/BHARATHANATYAM 36K (3)_4_11zon.jpg'
import bn47 from '../images/bharathanatyam-compress/BHARATHANATYAM 36L (2)_5_11zon.jpg'
import bn48 from '../images/bharathanatyam-compress/BHARATHANATYAM 36M (3)_6_11zon.jpg'
import bn49 from '../images/bharathanatyam-compress/BHARATHANATYAM 36N (2)_1_11zon.jpg'
import bn50 from '../images/bharathanatyam-compress/BHARATHANATYAM 38 N (3)_2_11zon.jpg'
import bn51 from '../images/bharathanatyam-compress/BHARATHANATYAM 38A (2)_3_11zon.jpg'
import bn52 from '../images/bharathanatyam-compress/BHARATHANATYAM 38B (3)_4_11zon.jpg'
import bn53 from '../images/bharathanatyam-compress/BHARATHANATYAM 38C (2)_5_11zon.jpg'
import bn54 from '../images/bharathanatyam-compress/BHARATHANATYAM 38D (3)_6_11zon.jpg'
import bn55 from '../images/bharathanatyam-compress/BHARATHANATYAM 38E (2)_1_11zon.jpg'
import bn56 from '../images/bharathanatyam-compress/BHARATHANATYAM 38F (3)_2_11zon.jpg'
import bn57 from '../images/bharathanatyam-compress/BHARATHANATYAM 38H (3)_3_11zon.jpg'
import bn58 from '../images/bharathanatyam-compress/BHARATHANATYAM 38J (3)_4_11zon.jpg'
import bn59 from '../images/bharathanatyam-compress/BHARATHANATYAM 38L (3)_5_11zon.jpg'
import bn60 from '../images/bharathanatyam-compress/BHARATHANATYAM 38P (2)_6_11zon.jpg'
import bn61 from '../images/bharathanatyam-compress/BHARATHANATYAM 39A (2)_1_11zon.jpg'
import bn62 from '../images/bharathanatyam-compress/BHARATHANATYAM 40A (2)_2_11zon.jpg'
import bn63 from '../images/bharathanatyam-compress/BHARATHANATYAM 40B (2)_3_11zon.jpg'
import bn64 from '../images/bharathanatyam-compress/BHARATHANATYAM 40C (3)_4_11zon.jpg'
import bn65 from '../images/bharathanatyam-compress/BHARATHANATYAM 40E (2)_5_11zon.jpg'
import bn66 from '../images/bharathanatyam-compress/BHARATHANATYAM 40F (2)_6_11zon.jpg'
import bn67 from '../images/bharathanatyam-compress/BHARATHANATYAM 40G (2)_1_11zon.jpg'
import bn68 from '../images/bharathanatyam-compress/BHARATHANATYAM 40H (2)_2_11zon.jpg'
import bn69 from '../images/bharathanatyam-compress/BHARATHANATYAM 40I (2)_3_11zon.jpg'
import bn70 from '../images/bharathanatyam-compress/BHARATHANATYM  34G (2)_4_11zon.jpg'
import bn71 from '../images/bharathanatyam-compress/BHARATHANATYM 30B (2)_5_11zon.jpg'
import bn72 from '../images/bharathanatyam-compress/BHARATHANATYM 38 O (2)_6_11zon.jpg'
import bn73 from '../images/bharathanatyam-compress/BHATATHANATYAM 34E (2)_7_11zon.jpg'
import Navbar2 from '../components/Navbar2'
import Footer2 from '../components/Footer2'

function Classical() {


    
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const dancecostume = () => {
        navigate('/dancecostume');
    };


    return (
        <div className='min-h-screen flex flex-col'>
            <Navbar2 />
            <div className='flex flex-col w-full h-auto lg:px-12 px-2  py-8 gap-8 items-start justify-start'>
                <div className=''><span className='text-[#828282] text-lg font-[500] font-[display2] cursor-pointer' onClick={dancecostume}>Dance Costume</span> {">"}  <span className='text-lg font-[500] font-[display2]'>Bharathanatyam</span></div>
         


                <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' >
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BARATHANATYAM 26B</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BARATHANATYAM 30E</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BARATHANATYAM 40D</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHA NATYAM 36H</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  24A</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  24B</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  28A</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  28C</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  28D</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  30C</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn11} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  30F</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn12} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  30G</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn13} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  32B</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn14} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  32D</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn15} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  32E</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn16} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  32F</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn17} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  34A</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn18} loading='lazy'  alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  34J</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn19} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  37.5 A</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn20} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  38G</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn21} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM  38I</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn22} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 26A</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn23} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 26C</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn24} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 28B</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn25} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 28E</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn26} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 30 D</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn27} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 30A</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn28} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 32A</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn29} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 32C</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn30} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 32G</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn31} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 34B</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn32} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 34C</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn33} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 34D</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn34} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 34F</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn35} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 34H</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn36} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 34I</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn37} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 36A</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn38} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 36B</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn39} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 36C</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn40} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 36D</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn41} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 36E</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn42} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 36F</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn43} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 36G</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn44} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 36I</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn45} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 36J</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn46} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 36K</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn47} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 36L</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn48} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 36M</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn49} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 36N</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn50} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 38 N</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn51} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 38A</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn52} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 38B</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn53} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 38C</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn54} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 38D</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn55} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 38E</div>
                    </div>

                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn56} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 38F</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn57} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 38H</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn58} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 38J</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn59} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 38L</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn60} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 38P</div>
                    </div>

                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn61} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 39A</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn62} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 40A</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn63} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 40B</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn64} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 40C</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn65} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 40E</div>
                    </div>

                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn66} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 40F</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn67} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 40G</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn68} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 40H</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn69} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYAM 40I</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn70} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYM  34G</div>
                    </div>

                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn71} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYM 30B</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn72} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHARATHANATYM 38 O</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn73} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>BHATATHANATYAM 34E</div>
                    </div>
               
                </div>

            </div>
            <Footer2 />
        </div>
    )
}

export default Classical
