import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import bn1 from '../images/semi classical dress-compress/HSS 1_1_11zon.jpg'
import bn2 from '../images/semi classical dress-compress/HSS 2_2_11zon.jpg'
import bn3 from '../images/semi classical dress-compress/HSS 3_3_11zon.jpg'
import bn4 from '../images/semi classical dress-compress/HSS 4_4_11zon.jpg'
import bn5 from '../images/semi classical dress-compress/HSS 5_5_11zon.jpg'
import bn6 from '../images/semi classical dress-compress/HSS 6_6_11zon.jpg'
import bn7 from '../images/semi classical dress-compress/KPF 1_1_11zon.jpg'
import bn8 from '../images/semi classical dress-compress/LPS 1_2_11zon.jpg'
import bn9 from '../images/semi classical dress-compress/UPS 1_3_11zon.jpg'
import bn10 from '../images/semi classical dress-compress/UPS 2_4_11zon.jpg'
import bn11 from '../images/semi classical dress-compress/UPS 3_5_11zon.jpg'
import Navbar2 from '../components/Navbar2'
import Footer2 from '../components/Footer2'


function SemiClassical() {


    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const dancecostume = () => {
        navigate('/dancecostume');
    };


    return (
        <div className='min-h-screen flex flex-col'>
            <Navbar2 />
            <div className='flex flex-col w-full h-auto lg:px-12 px-2  py-8 gap-8 items-start justify-start'>
                <div className=''><span className='text-[#828282] text-lg font-[500] font-[display2] cursor-pointer' onClick={dancecostume}>Dance Costume</span> {">"}  <span className='text-lg font-[500] font-[display2]'>Semi Classical</span></div>
         


                <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' >
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSS 1</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSS 2</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSS 3</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSS 4</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSS 5</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSS 6</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KPF 1</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPS 1</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>UPS 1</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>UPS 2</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={bn11} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>UPS 3</div>
                    </div>
                   
               
                </div>

            </div>
            <Footer2 />
        </div>
    )
}

export default SemiClassical
