import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import hsg1 from '../images/group hs-compress/HSG 1_1_11zon.jpg'
import hsg2 from '../images/group hs-compress/HSG 2_2_11zon.jpg'
import hsg3 from '../images/group hs-compress/HSG 4 (2)_3_11zon.jpg'
import hsg4 from '../images/group hs-compress/HSG 4 (4)_4_11zon.jpg'
import hsg5 from '../images/group hs-compress/HSG 4_5_11zon.jpg'

import lpg2 from '../images/group lp-compress/LPG 2_1_11zon.jpg'
import lpg4 from '../images/group lp-compress/LPG 4_2_11zon.jpg'
import lpg5 from '../images/group lp-compress/LPG 5_3_11zon.jpg'

import UPG1 from '../images/group up-compress/UPG 1 (1)_1_11zon.jpg'
import UPG2 from '../images/group up-compress/UPG 2_2_11zon.jpg'
import Footer2 from '../components/Footer2'
import Navbar2 from '../components/Navbar2'


function Group() {


    const [selectedType, setSelectedType] = useState('hs');
    const handleTypeClick = (type) => {
        setSelectedType(type);
    };
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const dancecostume = () => {
        navigate('/dancecostume');
    };


    return (
        <div className='min-h-screen flex flex-col'>
            <Navbar2 />
            <div className='flex flex-col w-full h-auto lg:px-12 px-2  py-8 gap-8 items-start justify-start'>
                <div className=''><span className='text-[#828282] text-lg font-[500] font-[display2] cursor-pointer' onClick={dancecostume}>Dance Costume</span> {">"}  <span className='text-lg font-[500] font-[display2]'>Group Dance</span></div>
                <div className='w-full flex flex-row'>
                    {/* <div className='w-[10%]  flex justify-start '>
                <div className='w-[60%] h-[33px] border-2 border-[#9D9494] rounded-[5px] flex flex-row justify-center items-center gap-2'>
                   <img src={filter} alt='filter'/>
                   <span className='text-lg font-[500] font-[display2]'>Filter</span>
                </div>
            </div> */}
                    {/* border-2 border-[#C30000] p-2 rounded-[20px] */}
                    <div className='w-[100%]  justify-center items-center flex md:flex-row flex-col lg:gap-10 gap-3'>
                        <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'hs' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('hs')}>HS</span>                     
                        <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'lp' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('lp')}>LP</span>
                        <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'up' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('up')}>UP</span>


                    </div>
                </div>


                <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'hs' ? 'grid' : 'none' }}>

                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsg1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSG 1</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsg2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSG 2</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsg3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSG 4</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsg4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSG 4</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsg5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSG 5</div>
                    </div>
             

                </div>




                <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'lp' ? 'grid' : 'none' }}>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpg2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPG 2</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpg4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPG 4</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpg5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPG 5</div>
                    </div>
                
                  
                </div>




                <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'up' ? 'grid' : 'none' }}>

                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={UPG1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>UPG 1</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={UPG2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>UPG 2</div>
                    </div>

                </div>




            </div>
            <Footer2 />
        </div>
    )
}

export default Group
