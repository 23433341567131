import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import hdmb18 from '../images/manavatty blowse hs-compress/HDMB 18_1_11zon.jpg'
import hsmb2 from '../images/manavatty blowse hs-compress/HSMB 2_2_11zon.jpg'
import hsmb20 from '../images/manavatty blowse hs-compress/HSMB 20_3_11zon.jpg'
import hsmb21 from '../images/manavatty blowse hs-compress/HSMB 21_4_11zon.jpg'
import hsmb22 from '../images/manavatty blowse hs-compress/HSMB 22_5_11zon.jpg'
import mb16 from '../images/manavatty blowse hs-compress/MB 1.6_6_11zon.jpg'
import mb2 from '../images/manavatty blowse hs-compress/MB 2_1_11zon.jpg'
import mb379 from '../images/manavatty blowse hs-compress/MB 3.7.9_2_11zon.jpg'
import mb4 from '../images/manavatty blowse hs-compress/MB 4_3_11zon.jpg'
import mb5 from '../images/manavatty blowse hs-compress/MB 5_4_11zon.jpg'
import mb82122232425 from '../images/manavatty blowse hs-compress/MB 8.21.22.23.24.25_5_11zon.jpg'
import mb10 from '../images/manavatty blowse hs-compress/MB 10_6_11zon.jpg'
import mb1116171819 from '../images/manavatty blowse hs-compress/MB 11.16.17.18.19_1_11zon.jpg'
import mb12 from '../images/manavatty blowse hs-compress/MB 12_2_11zon.jpg'
import mb14 from '../images/manavatty blowse hs-compress/MB 14_3_11zon.jpg'
import mb20 from '../images/manavatty blowse hs-compress/MB 20_4_11zon.jpg'

import kgmb1 from '../images/manavatty blowse lp-compress/KGMB 1_1_11zon.jpg'
import lpb2 from '../images/manavatty blowse lp-compress/LPB 2_2_11zon.jpg'
import lpb35 from '../images/manavatty blowse lp-compress/LPB 3.5_3_11zon.jpg'
import lpb48 from '../images/manavatty blowse lp-compress/LPB 4.8_4_11zon.jpg'
import lpb6 from '../images/manavatty blowse lp-compress/LPB 6_5_11zon.jpg'
import lpb7910 from '../images/manavatty blowse lp-compress/LPB 7,9,10_6_11zon.jpg'
import lpmb18 from '../images/manavatty blowse lp-compress/LPMB 18_1_11zon.jpg'

import hsm1 from '../images/manavatty dress hs-compress/HSM 1_1_11zon.jpg'
import hsm2 from '../images/manavatty dress hs-compress/HSM 2_2_11zon.jpg'
import hsm3 from '../images/manavatty dress hs-compress/HSM 3_3_11zon.jpg'
import hsm5 from '../images/manavatty dress hs-compress/HSM 5_4_11zon.jpg'
import hsm6 from '../images/manavatty dress hs-compress/HSM 6_5_11zon.jpg'
import hsm7 from '../images/manavatty dress hs-compress/HSM 7_6_11zon.jpg'
import hsm8 from '../images/manavatty dress hs-compress/HSM 8_1_11zon.jpg'
import hsm9 from '../images/manavatty dress hs-compress/HSM 9_2_11zon.jpg'
import hsm10 from '../images/manavatty dress hs-compress/HSM 10_3_11zon.jpg'
import hsm11 from '../images/manavatty dress hs-compress/HSM 11_4_11zon.jpg'
import hsm12 from '../images/manavatty dress hs-compress/HSM 12_5_11zon.jpg'
import hsm13 from '../images/manavatty dress hs-compress/HSM 13_6_11zon.jpg'
import hsm14 from '../images/manavatty dress hs-compress/HSM 14_1_11zon.jpg'
import hsm15 from '../images/manavatty dress hs-compress/HSM 15_2_11zon.jpg'
import hsm16 from '../images/manavatty dress hs-compress/HSM 16_3_11zon.jpg'
import hsm17 from '../images/manavatty dress hs-compress/HSM 17_4_11zon.jpg'
import hsm18 from '../images/manavatty dress hs-compress/HSM 18_5_11zon.jpg'
import hsm19 from '../images/manavatty dress hs-compress/HSM 19_6_11zon.jpg'
import hsm20 from '../images/manavatty dress hs-compress/HSM 20_1_11zon.jpg'
import hsm21 from '../images/manavatty dress hs-compress/HSM 21_2_11zon.jpg'
import hsm22 from '../images/manavatty dress hs-compress/HSM 22_3_11zon.jpg'

import kgm1 from '../images/manavatty dress lp-compress/KGM 1_1_11zon.jpg'
import kgm2 from '../images/manavatty dress lp-compress/KGM 2_2_11zon.jpg'
import kgm3 from '../images/manavatty dress lp-compress/KGM 3_3_11zon.jpg'
import kgm4 from '../images/manavatty dress lp-compress/KGM 4_4_11zon.jpg'
import kgm5 from '../images/manavatty dress lp-compress/KGM 5_5_11zon.jpg'
import kgm6 from '../images/manavatty dress lp-compress/KGM 6_6_11zon.jpg'
import lpm7 from '../images/manavatty dress lp-compress/LPM 7_1_11zon.jpg'
import lpm8 from '../images/manavatty dress lp-compress/LPM 8_2_11zon.jpg'
import lpm9 from '../images/manavatty dress lp-compress/LPM 9_3_11zon.jpg'
import lpm10 from '../images/manavatty dress lp-compress/LPM 10_4_11zon.jpg'
import lpm11 from '../images/manavatty dress lp-compress/LPM 11_5_11zon.jpg'
import lpm12 from '../images/manavatty dress lp-compress/LPM 12_6_11zon.jpg'
import lpm13 from '../images/manavatty dress lp-compress/LPM 13_1_11zon.jpg'
import lpm14 from '../images/manavatty dress lp-compress/LPM 14_2_11zon.jpg'
import lpm15 from '../images/manavatty dress lp-compress/LPM 15_3_11zon.jpg'
import lpm16 from '../images/manavatty dress lp-compress/LPM 16_4_11zon.jpg'
import lpm17 from '../images/manavatty dress lp-compress/LPM 17_5_11zon.jpg'
import lpm18 from '../images/manavatty dress lp-compress/LPM 18_6_11zon.jpg'
import lpm19 from '../images/manavatty dress lp-compress/LPM 19_7_11zon.jpg'
import Navbar2 from '../components/Navbar2'
import Footer2 from '../components/Footer2'



function Manavatty() {


    const [selectedType, setSelectedType] = useState('blousehs');
    const handleTypeClick = (type) => {
        setSelectedType(type);
    };
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const dancecostume = () => {
        navigate('/dancecostume');
    };


    return (
        <div className='min-h-screen flex flex-col'>
            <Navbar2 />
            <div className='flex flex-col w-full h-auto lg:px-12 px-2  py-8 gap-8 items-start justify-start'>
                <div className=''><span className='text-[#828282] text-lg font-[500] font-[display2] cursor-pointer' onClick={dancecostume}>Dance Costume</span> {">"}  <span className='text-lg font-[500] font-[display2]'>Oppana Manavatty</span></div>
                <div className='w-full flex flex-row'>
                    {/* <div className='w-[10%]  flex justify-start '>
                <div className='w-[60%] h-[33px] border-2 border-[#9D9494] rounded-[5px] flex flex-row justify-center items-center gap-2'>
                   <img src={filter} alt='filter'/>
                   <span className='text-lg font-[500] font-[display2]'>Filter</span>
                </div>
            </div> */}
                    {/* border-2 border-[#C30000] p-2 rounded-[20px] */}
                    <div className='w-[100%]  justify-center items-center flex md:flex-row flex-col lg:gap-10 gap-3'>
                        <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'blousehs' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('blousehs')}>BLOUSE HS</span>
                        <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'blouselp' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('blouselp')}>BLOUSE LP</span>
                        <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'dresshs' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('dresshs')}>DRESS HS</span>
                        <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'dresslp' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('dresslp')}>DRESS LP</span>


                    </div>
                </div>


                <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'blousehs' ? 'grid' : 'none' }}>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hdmb18} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HDMB 18</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsmb2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSMB 2</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsmb20} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSMB 20</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsmb21} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSMB 21</div>
                    </div>




                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsmb22} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSMB 22</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={mb16} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB 1.6</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={mb2} loading='lazy'  alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB 2</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={mb379} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB 3.7.9</div>
                    </div>





                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={mb4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB 4</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={mb5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB 5</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={mb82122232425} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB 8.21.22.23.24.25</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={mb10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB 10</div>
                    </div>




                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={mb1116171819} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB 11.16.17.18.19</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={mb12} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB 12</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={mb14} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB 14</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={mb20} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB 20</div>
                    </div>

                </div>


                <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'blouselp' ? 'grid' : 'none' }}>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={kgmb1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGMB 1</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpb2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPB 2</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpb35} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPB 3.5</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpb48} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPB 4.8</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpb6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPB 6</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpb7910} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPB 7,9,10</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpmb18} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPMB 18</div>
                    </div>

                </div>




                <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'dresshs' ? 'grid' : 'none' }}>

                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 1</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 2</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 3</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 5</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 6</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 7</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 8</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 9</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 10</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm11} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 11</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm12} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 12</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm13} loading='lazy'  alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 13</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm14} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 14</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm15} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 15</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm16} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 16</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm17} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 17</div>
                    </div>



                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm18} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 18</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm19} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 19</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm20} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 20</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm21} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 21</div>
                    </div>



                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={hsm22} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSM 22</div>
                    </div>





                </div>


                <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'dresslp' ? 'grid' : 'none' }}>

                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={kgm1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGM 1</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={kgm2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGM 2</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={kgm3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGM 3</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={kgm4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGM 4</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={kgm5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGM 5</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={kgm6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KGM 6</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpm7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPM 7</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpm8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPM 8</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpm9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPM 9</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpm10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPM 10</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpm11} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPM 11</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpm12} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPM 12</div>
                    </div>


                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpm13} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPM 13</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpm14} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPM 14</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpm15} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPM 15</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpm16} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPM 16</div>
                    </div>



                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpm17} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPM 17</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpm18} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPM 18</div>
                    </div>
                    <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                        <div className='w-full h-[90%] '>
                            <img src={lpm19} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPM 19</div>
                    </div>



               </div>

           </div>
            <Footer2 />
        </div>
    )
}

export default Manavatty
