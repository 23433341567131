import React,{useEffect,useState} from 'react'
import Navbar from '../components/Navbar'
import main from '../images/contact/main.png'
import location from '../images/contact/location.png'
import email from '../images/contact/email.png'
import phone from '../images/contact/phone.png'
import Footer from '../components/Footer'
import Navbar2 from '../components/Navbar2'
import Footer2 from '../components/Footer2'

function Contact() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName:'',
        email: '',
        phoneNumber: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSendEmail = () => {
        const recipientEmail = 'kairalicollectionspmna@gmail.com'; // Update with the recipient's email address
        const subject = 'New Message from contact us page';
        const body = `firstName: ${formData.firstName}\nlastName: ${formData.lastName}\nEmail: ${formData.email}\nPhone: ${formData.phoneNumber}\nMessage: ${formData.message}`;
    
        // Open default email client with pre-filled email template
        window.open(`mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);
    
        // Clear form data
        setFormData({
            firstName: '',
            lastName:'',
            email: '',
            phoneNumber: '',
            message: ''  
        });      
    
        // Optionally, you can redirect or perform any other action after sending the email
    };

    return (
        <div className='min-h-screen flex flex-col'>
            <Navbar2 />
            <div className='w-full lg:h-[354px] md:h-[340px] h-[150px] relative flex'>
                <img src={main} alt='main' style={{ width: '100%', height: '100%' }} />
                <div className='absolute flex w-full h-full items-center px-[13%] lg:text-5xl md:text-3xl text-xl font-[800] font-[display2]'>Contact Us</div>
            </div>
            <div className='w-full lg:h-[600px] h-[auto] flex lg:flex-row flex-col lg:px-12 px-2 gap-12 lg:gap-0 py-12 lg:py-0'>
                <div className=' lg:w-[50%] w-[100%] h-full flex flex-col  gap-3 lg:justify-center justify-start items-start text-left lg:px-[10%] px-0'>
                    <span className='text-4xl font-[700] font-[display2]'>Let's talk with us</span>
                    <span className='text-base font-[400] font-[display2]'>Questions, comments, or suggestions? Simply fill in the form and we’ll be in touch shortly.</span>
                    <div className='w-full flex flex-row gap-5 items-center mt-3'>
                        <img src={location} alt='location' />
                        <span className='text-lg font-[500] font-[display2]'>Perintalmanna, Malappuram - 679322 (Near Traffic Junction)</span>
                    </div>
                    <div className='w-full flex flex-row gap-5 items-center'>
                        <img src={phone} alt='location' />
                        <span className='text-lg font-[500] font-[display2]'>+91 9544870571</span>
                    </div>
                    <div className='w-full flex flex-row gap-5 items-center'>
                        <img src={email} alt='location' />
                        <span className='text-lg font-[500] font-[display2]'>kairalicollectionspmna@gmail.com</span>
                    </div>

                </div>
                <div className=' lg:w-[50%] w-[100%] h-full flex flex-col  gap-3 justify-center items-center  lg:px-[10%] px-0'>
                    <div className='flex flex-row gap-3 h-[50px] lg:w-[466px] md:w-[400px] w-[300px]'>
                        <input placeholder='First Name*' className='h-full w-[50%] rounded-[10px] border-2 border-[#828282] px-3'  name='firstName' value={formData.firstName} onChange={handleChange} />
                        <input placeholder='Last Name*' className='h-full w-[50%] rounded-[10px] border-2 border-[#828282] px-3' name='lastName' value={formData.lastName} onChange={handleChange}/>

                    </div>
                    <div className='flex  h-[50px] lg:w-[466px] md:w-[400px] w-[300px]'>
                        <input placeholder='Email*' className='h-full w-[100%] rounded-[10px] border-2 border-[#828282] px-3' name='email' value={formData.email} onChange={handleChange} />


                    </div>
                    <div className='flex  h-[50px] lg:w-[466px] md:w-[400px] w-[300px]'>
                        <input placeholder='Phone Number*' className='h-full w-[100%] rounded-[10px] border-2 border-[#828282] px-3' name='phoneNumber' value={formData.phoneNumber} onChange={handleChange} />


                    </div>
                    <div className='flex  h-[140px] lg:w-[466px] md:w-[400px] w-[300px]'>
                        <input placeholder='Your Message*' className='h-full w-[100%] rounded-[10px] border-2 border-[#828282] px-3' name='message' value={formData.message} onChange={handleChange} />


                    </div>
                    <div className='flex bg-[black] rounded-[10px] h-[50px] lg:w-[466px] md:w-[400px] w-[300px] justify-center items-center text-xl font-[700] text-[white] font-[display2] cursor-pointer' onClick={handleSendEmail}>

                        Submit

                    </div>

                </div>

            </div>
            <div className='w-full lg:h-[725px] md:h-[625px] h-[525px]'>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.8335990780606!2d76.22337177457729!3d10.975929655483123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7cd27e4e6f9ad%3A0xb7e2c3f8f9cd4633!2sKairali%20dance%20collections!5e0!3m2!1sen!2sin!4v1724909350914!5m2!1sen!2sin"
                    style={{ border: 0,width:'100%',height:'100%' }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
            <Footer2/>
        </div>
    )
}

export default Contact
