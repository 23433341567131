import React,{useEffect} from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Navbar2 from '../components/Navbar2';
import Footer2 from '../components/Footer2';

function Terms() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='min-h-screen flex flex-col'>
            <Navbar2 />
            <div className='flex flex-col  lg:px-12 px-2 py-10 gap-10'>
                <div className='w-full flex justify-center items-center font-[600] lg:text-4xl text-2xl text-[display2]'>Terms & Conditions</div>
                <div className='flex flex-col w-full text-left gap-6'>
                    <span className='lg:text-3xl  text-xl font-[400] font-[display2]'>1. Acceptance of Terms</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>By accessing and using this website, you agree to be bound by the
                        following Terms and Conditions, our Privacy Policy, and any additional terms that may apply to specific sections
                        of the site or to products and services available through the website.
                        If you do not agree with these terms, please do not use the website.</span>

                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>2. Rental Agreement</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>When renting a dance dress from Kairali Dance Collection,
                        you enter into a rental agreement under these terms:</span>

                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>2.1 Rental Agreement</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; The rental period begins
                        the day the dress is delivered
                        to you and ends on the agreed-upon return date.</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Late returns will
                        incur additional charges, which are calculated on a daily basis.</span>


                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>2.2 Rental Fees and Deposits</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; The rental fee must be paid at
                        the time of placing an order.</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; A refundable security deposit may
                        be required. This deposit will be refunded once the dress is returned in its original condition.</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; If the dress is damaged, soiled, or not returned,
                        part or all of the deposit may be withheld to cover repair or replacement costs.</span>



                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>2.3 Dress Condition</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; All dresses are professionally
                        cleaned before rental.</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; You are responsible for the care of the dress
                        during the rental period and must return it in good condition.</span>



                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>2.4 Size and Fit</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; It is your responsibility to ensure you choose the correct size.
                        Kairali Dance Collection does not guarantee that the dress will fit perfectly,
                        and no refunds will be provided for fit issues.</span>


                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>3. Cancellations and Refunds</span>




                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>3.1 Cancellation by You</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Orders may be canceled
                        up to 2 days before the scheduled delivery date for a full refund.</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; If you cancel after the cutoff date,
                        a cancellation fee of 10% will be charged.</span>

                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>3.2 Refunds</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Refunds will not be provided if the dress does not fit,
                        arrives after the event
                        (due to incorrect information provided by the user), or for
                        reasons beyond Kairali Dance Collection's control (such as incorrect size selection).</span>

                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>4. Shipping and Returns</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Shipping costs are the customer’s
                        responsibility unless otherwise stated.</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp;Dresses must be returned using the pre-paid
                        return label provided. You are responsible for ensuring that the package
                        is dropped off at the shipping provider on or before the return date.</span>



                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>5. Damage and Loss</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; If the dress is returned damaged, stained, or altered,
                        the customer is responsible for repair costs or replacement.</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp;In the event of a lost or stolen dress,
                        you will be charged up to the full retail value of the dress.</span>


                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>6. Damage and Loss</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; If the dress is returned damaged, stained, or altered,
                        the customer is responsible for repair costs or replacement.</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp;In the event of a lost or stolen dress,
                        you will be charged up to the full retail value of the dress.</span>



                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>7. Limitation of Liability</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Kairali Dance Collection is not liable for any damages that may
                        arise from the use or misuse of the rented dresses, including but not limited
                        to personal injury, allergic reactions, or property damage.</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; We are not responsible for delays
                        in delivery due to third-party shipping carriers or other causes beyond our control.</span>




                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>8. Intellectual Property</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; All content, including text, images, logos, and other materials on
                        this website, is the intellectual property of Kairali Dance Collection and is protected
                        by copyright and other intellectual property laws.</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; You may not use, copy, or distribute any content
                        from the website without our express written permission.</span>





                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>9. Changes to Terms</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Kairali Dance Collection reserves the right to change
                        or update these Terms and Conditions at any time.
                        It is your responsibility to review them regularly.
                        Your continued use of the website after any changes indicates acceptance of those changes.</span>





                </div>
            </div>
            <Footer2 />
        </div>
    )
}

export default Terms
