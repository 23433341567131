import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import p1 from '../images/Propertice/manavalan-compress/VPL (1)_1_11zon.jpg'
import p2 from '../images/Propertice/manavalan-compress/VPT_2_11zon.jpg'

import p3 from '../images/Propertice/Propertice/PKM 1 (1).jpg'
import p4 from '../images/Propertice/Propertice/PS 1.jpg'
import p5 from '../images/Propertice/Propertice/PS 10.jpg'
import p6 from '../images/Propertice/Propertice/PS 11.jpg'
import p7 from '../images/Propertice/Propertice/PS 12.jpg'
import p8 from '../images/Propertice/Propertice/PS 13.jpg'
import p9 from '../images/Propertice/Propertice/PS 14.jpg'
import p10 from '../images/Propertice/Propertice/PS 15.jpg'

import p11 from '../images/Propertice/Propertice/PS 16.jpg'
import p12 from '../images/Propertice/Propertice/PS 17.jpg'
import p13 from '../images/Propertice/Propertice/PS 18.jpg'
import p14 from '../images/Propertice/Propertice/PS 19.jpg'
import p15 from '../images/Propertice/Propertice/PS 2.jpg'
import p16 from '../images/Propertice/Propertice/PS 21 (1).jpg'
import p17 from '../images/Propertice/Propertice/PS 22.jpg'
import p18 from '../images/Propertice/Propertice/PS 23.jpg'
import p19 from '../images/Propertice/Propertice/PS 24 (1).jpg'
import p20 from '../images/Propertice/Propertice/PS 24 (2).jpg'

import p21 from '../images/Propertice/Propertice/PS 26.jpg'
import p22 from '../images/Propertice/Propertice/PS 27.jpg'
import p23 from '../images/Propertice/Propertice/PS 28 (1).jpg'
import p24 from '../images/Propertice/Propertice/PS 29 (2).jpg'
import p25 from '../images/Propertice/Propertice/PS 3 (2).jpg'
import p26 from '../images/Propertice/Propertice/PS 30.jpg'
import p27 from '../images/Propertice/Propertice/PS 31.jpg'
import p28 from '../images/Propertice/Propertice/PS 32.33.jpg'
import p29 from '../images/Propertice/Propertice/PS 34 (1).jpg'
import p30 from '../images/Propertice/Propertice/PS 35 (1).jpg'

import p31 from '../images/Propertice/Propertice/PS 36.jpg'
import p32 from '../images/Propertice/Propertice/PS 38.jpg'
import p33 from '../images/Propertice/Propertice/PS 39.jpg'
import p34 from '../images/Propertice/Propertice/PS 4.jpg'
import p35 from '../images/Propertice/Propertice/PS 41.jpg'
import p36 from '../images/Propertice/Propertice/PS 43 (1).jpg'
import p37 from '../images/Propertice/Propertice/PS 45 (1).jpg'
import p38 from '../images/Propertice/Propertice/PS 46 (1).jpg'
import p39 from '../images/Propertice/Propertice/PS 47 (1).jpg'
import p40 from '../images/Propertice/Propertice/PS 48 (2).jpg'

import p41 from '../images/Propertice/Propertice/PS 49.jpg'
import p42 from '../images/Propertice/Propertice/PS 5.jpg'
import p43 from '../images/Propertice/Propertice/PS 50.jpg'
import p44 from '../images/Propertice/Propertice/PS 51.jpg'
import p45 from '../images/Propertice/Propertice/PS 52.jpg'
import p46 from '../images/Propertice/Propertice/PS 53 (1).jpg'
import p47 from '../images/Propertice/Propertice/PS 57 (1).jpg'
import p48 from '../images/Propertice/Propertice/PS 58.jpg'
import p49 from '../images/Propertice/Propertice/PS 59 (1).jpg'
import p50 from '../images/Propertice/Propertice/PS 59 (2).jpg'

import p51 from '../images/Propertice/Propertice/PS 59 (4).jpg'
import p52 from '../images/Propertice/Propertice/PS 59.jpg'
import p53 from '../images/Propertice/Propertice/PS 6.jpg'
import p54 from '../images/Propertice/Propertice/PS 60 (1).jpg'
import p55 from '../images/Propertice/Propertice/PS 60 (2).jpg'
import p56 from '../images/Propertice/Propertice/PS 61.jpg'
import p57 from '../images/Propertice/Propertice/PS 62.jpg'
import p58 from '../images/Propertice/Propertice/PS 63 (5).jpg'
import p59 from '../images/Propertice/Propertice/PS 64.jpg'
import p60 from '../images/Propertice/Propertice/PS 65 (2).jpg'

import p61 from '../images/Propertice/Propertice/PS 66 (1).jpg'
import p62 from '../images/Propertice/Propertice/PS 67.jpg'
import p63 from '../images/Propertice/Propertice/PS 68.jpg'
import p64 from '../images/Propertice/Propertice/PS 69.jpg'
import p65 from '../images/Propertice/Propertice/PS 7.jpg'
import p66 from '../images/Propertice/Propertice/PS 70.jpg'
import p67 from '../images/Propertice/Propertice/PS 73 (1).jpg'
import p68 from '../images/Propertice/Propertice/PS 74.jpg'
import p69 from '../images/Propertice/Propertice/PS 75 (1).jpg'
import p70 from '../images/Propertice/Propertice/PS 76 (1).jpg'

import p71 from '../images/Propertice/Propertice/PS 79.jpg'
import p72 from '../images/Propertice/Propertice/PS 8.jpg'
import p73 from '../images/Propertice/Propertice/PS 81.jpg'
import p74 from '../images/Propertice/Propertice/PS 82 (1).jpg'
import p75 from '../images/Propertice/Propertice/PS 83.jpg'
import p76 from '../images/Propertice/Propertice/PS 84.jpg'
import p77 from '../images/Propertice/Propertice/PS 86.jpg'
import p78 from '../images/Propertice/Propertice/PS 87.jpg'
import p79 from '../images/Propertice/Propertice/PS 88.jpg'
import p80 from '../images/Propertice/Propertice/PS 89.jpg'

import p81 from '../images/Propertice/Propertice/PS 90.jpg'
import p82 from '../images/Propertice/Propertice/PS 91.jpg'
import p83 from '../images/Propertice/Propertice/PS 92.jpg'
import p84 from '../images/Propertice/Propertice/PS 9.jpg'
import p85 from '../images/Propertice/Propertice/PS 80.jpg'
import Navbar2 from '../components/Navbar2'
import Footer2 from '../components/Footer2'


function Properties() {

 
  const navigate = useNavigate()

  useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  

  const home = () => {
      navigate('/');
    };


  return (
    <div className='min-h-screen flex flex-col'>
      <Navbar2/>
      <div className='flex flex-col w-full h-auto lg:px-12 px-2  py-8 gap-8 items-start justify-start'>
          <div  className=''><span className='text-[#828282] text-lg font-[500] font-[display] cursor-pointer' onClick={home}>Home</span> {">"}  <span className='text-lg font-[500] font-[display]'>Properties</span></div>
         
          <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' >
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p1} loading='lazy'  alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>VPL</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p2} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>VPT</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p3} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PKM 1</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p4} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 1</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p5} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 10</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p6} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 11</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p7} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 12</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p8} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 13</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p9} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 14</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p10} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 15</div>
               </div>



               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p11} loading='lazy'  alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 16</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p12} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 17</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p13} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 18</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p14} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 19</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p15} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 2</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p16} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 21</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p17} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 22</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p18} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 23</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p19} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 24 1</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p20} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 24 2</div>
               </div>




               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p21} loading='lazy'  alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 26</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p22} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 27</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p23} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 28</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p24} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 29</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p25} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 3</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p26} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 30</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p27} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 31</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p28} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 32.33</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p29} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 34</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p30} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 35</div>
               </div>





               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p31} loading='lazy'  alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 36</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p32} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 38</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p33} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 39</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p34} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 4</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p35} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 41</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p36} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 43</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p37} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 45</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p38} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 46</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p39} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 47</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p40} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 48</div>
               </div>




               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p41} loading='lazy'  alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 49</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p42} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 5</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p43} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 50</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p44} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 51</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p45} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 52</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p46} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 53</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p47} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 57</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p48} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 58</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p49} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS59 1</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p50} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS59 2</div>
               </div>




               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p51} loading='lazy'  alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS59 3</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p52} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS59 4</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p53} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 6</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p54} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS60 1</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p55} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS60 2</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p56} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 61</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p57} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 62</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p58} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 63</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p59} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 64</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p60} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 65</div>
               </div>





               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p61} loading='lazy'  alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 66</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p62} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 67</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p63} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 68</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p64} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 69</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p65} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 7</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p66} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 70</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p67} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 73</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p68} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 74</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p69} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 75</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p70} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 76</div>
               </div>





               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p71} loading='lazy'  alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 79</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p72} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 8</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p73} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 81</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p74} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 82</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p75} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 83</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p76} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 84</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p77} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 86</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p78} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 87</div>
               </div>  <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p79} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 88</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p80} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 89</div>
               </div>




               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p81} loading='lazy'  alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 90</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p82} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 91</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p83} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 92</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p84} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 9</div>
               </div>
               <div className='lg:h-[250px] h-[180px] md:h-[200px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={p85} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display]'>PS 80</div>
               </div>
               
               
               
          </div>
      </div>
      <Footer2/>
    </div>
  )
}

export default Properties
