import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../images/navbar/logo.png';
import { Link } from 'react-router-dom';

function Navbar2({ scrollToAbout,scrollToProduct })  {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
const navigate = useNavigate()

const contact = () => {
  navigate('/contact');
};

const home = () => {
  navigate('/');
};


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      {/* Navbar Container */}
      <div className='w-full h-[74px] bg-[black] flex flex-row'>
        {/* Logo Section */}
        <div className='w-[30%] h-[100%] hidden lg:flex px-12'>
          <img src={logo} alt='logo' onClick={home} className='cursor-pointer' />
        </div>

        {/* Desktop Menu Items */}
        <div className='w-[40%] h-[100%]  items-center justify-center flex-row gap-12 text-[white] hidden lg:flex'>
          <span className='font-[display2] hover:text-blue-500 font-[600] text-sm cursor-pointer' onClick={home}>Home</span>
          
          <span className='font-[display2] hover:text-blue-500 font-[600] text-sm cursor-pointer' onClick={contact}>Contact</span>

        </div>

        {/* Empty Right Section (for alignment) */}
        <div className='w-[30%] h-[100%] hidden lg:flex'></div>

        {/* Mobile Menu Button */}
        <div className='w-[100%] h-[100%] lg:hidden flex justify-between items-center px-4'>
          <button onClick={toggleMenu} className='hover:text-blue-500 focus:outline-none text-[white]'>
            <svg className='h-6 w-6' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16m-7 6h7'></path>
            </svg>
          </button>
          <img src={logo} alt='logo' onClick={home}/>
        </div>
      </div>

      {/* Mobile Menu Dropdown */}
      {isMenuOpen && (
        <div className='lg:hidden  flex flex-col items-center'>
          <span className='block hover:text-blue-500 py-2 text-[16px] font-[600] font-[Roboto] '  onClick={home}>
            Home
          </span>
         
          <span className='block hover:text-blue-500 py-2 text-[16px] font-[600] font-[Roboto] '  onClick={contact}>
            Contact 
          </span>
        </div>
      )}
    </div>
  );
}

export default Navbar2;
