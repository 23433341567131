import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import f1 from '../images/Ornaments/black metel-compress/B 1 (1)_1_11zon.jpg'
import f2 from '../images/Ornaments/black metel-compress/B 2 (2)_2_11zon.jpg'
import f3 from '../images/Ornaments/black metel-compress/B 3 (3)_3_11zon.jpg'
import f4 from '../images/Ornaments/black metel-compress/B 4 (1)_4_11zon.jpg'
import f5 from '../images/Ornaments/black metel-compress/B 4 (3)_5_11zon.jpg'
import f6 from '../images/Ornaments/black metel-compress/B 5 (1)_6_11zon.jpg'

import c1 from '../images/Ornaments/chettinad ornaments-compress/CHT 1_1_11zon.jpg'
import c2 from '../images/Ornaments/chettinad ornaments-compress/CHT 2 (1)_2_11zon.jpg'
import c3 from '../images/Ornaments/chettinad ornaments-compress/CHT 3_3_11zon.jpg'
import c4 from '../images/Ornaments/chettinad ornaments-compress/CHT 4.5_4_11zon.jpg'
import c5 from '../images/Ornaments/chettinad ornaments-compress/CHT 4_5_11zon.jpg'
import c6 from '../images/Ornaments/chettinad ornaments-compress/CHTA 1 (2)_6_11zon.jpg'
import c7 from '../images/Ornaments/chettinad ornaments-compress/CHTC 1_1_11zon.jpg'
import c8 from '../images/Ornaments/chettinad ornaments-compress/CHTJ 1_2_11zon.jpg'
import c9 from '../images/Ornaments/chettinad ornaments-compress/CHTJ 2_3_11zon.jpg'
import c10 from '../images/Ornaments/chettinad ornaments-compress/CHTJ 3_4_11zon.jpg'
import c11 from '../images/Ornaments/chettinad ornaments-compress/CHTJ 4_5_11zon.jpg'
import c12 from '../images/Ornaments/chettinad ornaments-compress/CHTJ 5_1_11zon.jpg'
import c13 from '../images/Ornaments/chettinad ornaments-compress/CHTJ 6_1_11zon.jpg'
import c14 from '../images/Ornaments/chettinad ornaments-compress/CHTJ 7_2_11zon.jpg'
import c15 from '../images/Ornaments/chettinad ornaments-compress/CHTJ 8_3_11zon.jpg'
import c16 from '../images/Ornaments/chettinad ornaments-compress/CHTJ 9_4_11zon.jpg'
import c17 from '../images/Ornaments/chettinad ornaments-compress/CHTN 10_3_11zon.jpg'
import c18 from '../images/Ornaments/chettinad ornaments-compress/CHTN 11_4_11zon.jpg'
import c19 from '../images/Ornaments/chettinad ornaments-compress/CHTN 12_5_11zon.jpg'
import c20 from '../images/Ornaments/chettinad ornaments-compress/CHTN 13_6_11zon.jpg'
import c21 from '../images/Ornaments/chettinad ornaments-compress/CHTN 5_5_11zon.jpg'
import c22 from '../images/Ornaments/chettinad ornaments-compress/CHTN 7_1_11zon.jpg'
import c23 from '../images/Ornaments/chettinad ornaments-compress/CHTN 8_1_11zon.jpg'
import c24 from '../images/Ornaments/chettinad ornaments-compress/CHTN 9_2_11zon.jpg'

import cl1 from '../images/Ornaments/classical ornaments-compress/CO 1_1_11zon.jpg'
import cl2 from '../images/Ornaments/classical ornaments-compress/CO 2_2_11zon.jpg'
import cl3 from '../images/Ornaments/classical ornaments-compress/CO 7_3_11zon.jpg'
import cl4 from '../images/Ornaments/classical ornaments-compress/CO 8 (2)_4_11zon.jpg'
import cl5 from '../images/Ornaments/classical ornaments-compress/CO 9_5_11zon.jpg'
import cl6 from '../images/Ornaments/classical ornaments-compress/CO 11_1_11zon.jpg'
import cl7 from '../images/Ornaments/classical ornaments-compress/CO 13_2_11zon.jpg'
import cl8 from '../images/Ornaments/classical ornaments-compress/CO 14 (2)_3_11zon.jpg'
import cl9 from '../images/Ornaments/classical ornaments-compress/CO 14_4_11zon.jpg'
import cl10 from '../images/Ornaments/classical ornaments-compress/CO 15_5_11zon.jpg'
import cl11 from '../images/Ornaments/classical ornaments-compress/CO 16 (1)_1_11zon.jpg'
import cl12 from '../images/Ornaments/classical ornaments-compress/CO 16 (2)_2_11zon.jpg'
import cl13 from '../images/Ornaments/classical ornaments-compress/CO 17_3_11zon.jpg'
import cl14 from '../images/Ornaments/classical ornaments-compress/CO 19_4_11zon.jpg'
import cl15 from '../images/Ornaments/classical ornaments-compress/CO 20 (2)_5_11zon.jpg'
import cl16 from '../images/Ornaments/classical ornaments-compress/CO 21 (2)_1_11zon.jpg'
import cl17 from '../images/Ornaments/classical ornaments-compress/CO 21_2_11zon.jpg'
import cl18 from '../images/Ornaments/classical ornaments-compress/CO 23 (1)_3_11zon.jpg'
import cl19 from '../images/Ornaments/classical ornaments-compress/CO 24_4_11zon.jpg'
import cl20 from '../images/Ornaments/classical ornaments-compress/CO 25_1_11zon.jpg'
import cl21 from '../images/Ornaments/classical ornaments-compress/CO 26_2_11zon.jpg'
import cl22 from '../images/Ornaments/classical ornaments-compress/CO 27_3_11zon.jpg'
import cl23 from '../images/Ornaments/classical ornaments-compress/CO 28_4_11zon.jpg'
import cl24 from '../images/Ornaments/classical ornaments-compress/CO 29_5_11zon.jpg'
import cl25 from '../images/Ornaments/classical ornaments-compress/CO 30_1_11zon.jpg'
import cl26 from '../images/Ornaments/classical ornaments-compress/CO 31_2_11zon.jpg'
import cl27 from '../images/Ornaments/classical ornaments-compress/CO 33_3_11zon.jpg'

import jlp1 from '../images/Ornaments/folk ornaments-compress/FO 1_1_11zon.jpg'
import jlp2 from '../images/Ornaments/folk ornaments-compress/FO 2_2_11zon.jpg'
import jlp3 from '../images/Ornaments/folk ornaments-compress/FO 3_3_11zon.jpg'
import jlp5 from '../images/Ornaments/folk ornaments-compress/FO 4_4_11zon.jpg'
import jlp6 from '../images/Ornaments/folk ornaments-compress/FO 5_5_11zon.jpg'
import jlp7 from '../images/Ornaments/folk ornaments-compress/FO 6_6_11zon.jpg'
import jlp8 from '../images/Ornaments/folk ornaments-compress/TF 9_1_11zon.jpg'
import jlp9 from '../images/Ornaments/folk ornaments-compress/TF 10_2_11zon.jpg'
import jlp10 from '../images/Ornaments/folk ornaments-compress/TF 11_3_11zon.jpg'


import p1 from '../images/Ornaments/golden jimiki-compress/J 1_1_11zon.jpg'
import p2 from '../images/Ornaments/golden jimiki-compress/J 3_2_11zon.jpg'
import p3 from '../images/Ornaments/golden jimiki-compress/J 4_3_11zon.jpg'
import p4 from '../images/Ornaments/golden jimiki-compress/J 5_4_11zon.jpg'
import p5 from '../images/Ornaments/golden jimiki-compress/J 7_5_11zon.jpg'
import p6 from '../images/Ornaments/golden jimiki-compress/J 9_1_11zon.jpg'
import p7 from '../images/Ornaments/golden jimiki-compress/JB 10_2_11zon.jpg'
import p8 from '../images/Ornaments/golden jimiki-compress/JB 12_3_11zon.jpg'
import p9 from '../images/Ornaments/golden jimiki-compress/JB 13 (2)_4_11zon.jpg'
import p10 from '../images/Ornaments/golden jimiki-compress/JB 13_5_11zon.jpg'
import p11 from '../images/Ornaments/golden jimiki-compress/JC 1_1_11zon.jpg'
import p12 from '../images/Ornaments/golden jimiki-compress/JS 5_2_11zon.jpg'
import p13 from '../images/Ornaments/golden jimiki-compress/JS 6_3_11zon.jpg'



import mm1 from '../images/Ornaments/golden set-compress/G 1 (1)_1_11zon.jpg'
import mm2 from '../images/Ornaments/golden set-compress/G 1 (2)_2_11zon.jpg'
import mm3 from '../images/Ornaments/golden set-compress/G 2 (2)_3_11zon.jpg'
import mm4 from '../images/Ornaments/golden set-compress/G 3 (2)_4_11zon.jpg'

import ptlp1 from '../images/Ornaments/group headset-compress/GH 1 (2)_11zon.jpg'
import ptlp2 from '../images/Ornaments/group headset-compress/SH 1 (2)_11zon.jpg'

import gs1 from '../images/Ornaments/ilakathali-compress/ILT 1_11zon.jpg'
import gs2 from '../images/Ornaments/ilakathali-compress/ILT 2_11zon.jpg'

import ksh1 from '../images/Ornaments/kashimala-compress/L 2_1_11zon.jpg'
import ksh2 from '../images/Ornaments/kashimala-compress/L 3_2_11zon.jpg'
import ksh3 from '../images/Ornaments/kashimala-compress/L 4_3_11zon.jpg'
import ksh4 from '../images/Ornaments/kashimala-compress/L 5_4_11zon.jpg'
import ksh5 from '../images/Ornaments/kashimala-compress/L 6_5_11zon.jpg'
import ksh6 from '../images/Ornaments/kashimala-compress/L 7_6_11zon.jpg'

import lpt1 from '../images/Ornaments/lice chocker-compress/CH 1_1_11zon.jpg'
import lpt2 from '../images/Ornaments/lice chocker-compress/CH 2_2_11zon.jpg'
import lpt3 from '../images/Ornaments/lice chocker-compress/CH 3_3_11zon.jpg'
import lpt4 from '../images/Ornaments/lice chocker-compress/CH 4_4_11zon.jpg'
import lpt5 from '../images/Ornaments/lice chocker-compress/CH 5_5_11zon.jpg'
import lpt6 from '../images/Ornaments/lice chocker-compress/CH 6_6_11zon.jpg'
import lpt7 from '../images/Ornaments/lice chocker-compress/CH 7_7_11zon.jpg'

import l9 from '../images/Ornaments/Long Chain Gold-compress/L 9_11zon.jpg'

import mo1 from '../images/Ornaments/manavatty ornaments-compress/MB 1 (2)_1_11zon.jpg'
import mo2 from '../images/Ornaments/manavatty ornaments-compress/MB 1 (4)_2_11zon.jpg'
import mo3 from '../images/Ornaments/manavatty ornaments-compress/MB 1_3_11zon.jpg'
import mo4 from '../images/Ornaments/manavatty ornaments-compress/MB 3 (1)_4_11zon.jpg'
import mo5 from '../images/Ornaments/manavatty ornaments-compress/MB 3 (3)_1_11zon.jpg'
import mo6 from '../images/Ornaments/manavatty ornaments-compress/MB 4_2_11zon.jpg'
import mo7 from '../images/Ornaments/manavatty ornaments-compress/MB 5_3_11zon.jpg'
import mo8 from '../images/Ornaments/manavatty ornaments-compress/MB 6_4_11zon.jpg'
import mo9 from '../images/Ornaments/manavatty ornaments-compress/MS 1 (1)_1_11zon.jpg'
import mo10 from '../images/Ornaments/manavatty ornaments-compress/MS 2 (1)_2_11zon.jpg'
import mo11 from '../images/Ornaments/manavatty ornaments-compress/MSH 1 (2)_3_11zon.jpg'
import mo12 from '../images/Ornaments/manavatty ornaments-compress/VMB 2_4_11zon.jpg'
import mo13 from '../images/Ornaments/manavatty ornaments-compress/VMM 1 (1)_1_11zon.jpg'
import mo14 from '../images/Ornaments/manavatty ornaments-compress/VMM 1 (2)_2_11zon.jpg'
import mo15 from '../images/Ornaments/manavatty ornaments-compress/VMS 1_3_11zon.jpg'


import mk1 from '../images/Ornaments/maveli krishnan ornaments-compress/KO 1_1_11zon.jpg'
import mk2 from '../images/Ornaments/maveli krishnan ornaments-compress/KO 11 BIG_2_11zon.jpg'
import mk3 from '../images/Ornaments/maveli krishnan ornaments-compress/KO 11 SMALL_3_11zon.jpg'
import mk4 from '../images/Ornaments/maveli krishnan ornaments-compress/KO 11_4_11zon.jpg'
import mk5 from '../images/Ornaments/maveli krishnan ornaments-compress/KO 13 (1)_1_11zon.jpg'
import mk6 from '../images/Ornaments/maveli krishnan ornaments-compress/KO 13 (2)_2_11zon.jpg'
import mk7 from '../images/Ornaments/maveli krishnan ornaments-compress/KO 14 (2)_3_11zon.jpg'
import mk8 from '../images/Ornaments/maveli krishnan ornaments-compress/KO 15_4_11zon.jpg'
import mk9 from '../images/Ornaments/maveli krishnan ornaments-compress/KO 2_2_11zon.jpg'
import mk10 from '../images/Ornaments/maveli krishnan ornaments-compress/KO 3_3_11zon.jpg'
import mk11 from '../images/Ornaments/maveli krishnan ornaments-compress/KO 4_4_11zon.jpg'
import mk12 from '../images/Ornaments/maveli krishnan ornaments-compress/KO 5_5_11zon.jpg'
import mk13 from '../images/Ornaments/maveli krishnan ornaments-compress/KO 9_1_11zon.jpg'

import n1 from '../images/Ornaments/neckless-compress/MO 1 (2)_1_11zon.jpg'
import n2 from '../images/Ornaments/neckless-compress/N 1_2_11zon.jpg'
import n3 from '../images/Ornaments/neckless-compress/N 10_5_11zon.jpg'
import n4 from '../images/Ornaments/neckless-compress/N 11_6_11zon.jpg'
import n5 from '../images/Ornaments/neckless-compress/N 12_1_11zon.jpg'
import n6 from '../images/Ornaments/neckless-compress/N 13_2_11zon.jpg'
import n7 from '../images/Ornaments/neckless-compress/N 14_3_11zon.jpg'
import n8 from '../images/Ornaments/neckless-compress/N 15 (1)_4_11zon.jpg'
import n9 from '../images/Ornaments/neckless-compress/N 16_5_11zon.jpg'
import n10 from '../images/Ornaments/neckless-compress/N 17_6_11zon.jpg'
import n11 from '../images/Ornaments/neckless-compress/N 18_1_11zon.jpg'
import n12 from '../images/Ornaments/neckless-compress/N 19_2_11zon.jpg'
import n13 from '../images/Ornaments/neckless-compress/N 2_3_11zon.jpg'
import n14 from '../images/Ornaments/neckless-compress/N 20_3_11zon.jpg'
import n15 from '../images/Ornaments/neckless-compress/N 21_4_11zon.jpg'
import n16 from '../images/Ornaments/neckless-compress/N 22_5_11zon.jpg'
import n17 from '../images/Ornaments/neckless-compress/N 23_6_11zon.jpg'
import n18 from '../images/Ornaments/neckless-compress/N 24_1_11zon.jpg'
import n19 from '../images/Ornaments/neckless-compress/N 25_2_11zon.jpg'
import n20 from '../images/Ornaments/neckless-compress/N 26_3_11zon.jpg'
import n21 from '../images/Ornaments/neckless-compress/N 27_4_11zon.jpg'
import n22 from '../images/Ornaments/neckless-compress/N 28_5_11zon.jpg'
import n23 from '../images/Ornaments/neckless-compress/N 3_4_11zon.jpg'
import n24 from '../images/Ornaments/neckless-compress/N 4_5_11zon.jpg'
import n25 from '../images/Ornaments/neckless-compress/N 5_6_11zon.jpg'
import n26 from '../images/Ornaments/neckless-compress/N 6_1_11zon.jpg'
import n27 from '../images/Ornaments/neckless-compress/N 7_2_11zon.jpg'
import n28 from '../images/Ornaments/neckless-compress/N 8_3_11zon.jpg'
import n29 from '../images/Ornaments/neckless-compress/N 9_4_11zon.jpg'


import i1 from '../images/Ornaments/oppana idamala-compress/OE 1 (1)_1_11zon.jpg'
import i2 from '../images/Ornaments/oppana idamala-compress/OE 10 (1)_4_11zon.jpg'
import i3 from '../images/Ornaments/oppana idamala-compress/OE 11 (3)_5_11zon.jpg'
import i4 from '../images/Ornaments/oppana idamala-compress/OE 12_6_11zon.jpg'
import i5 from '../images/Ornaments/oppana idamala-compress/OE 2 (1)_2_11zon.jpg'
import i6 from '../images/Ornaments/oppana idamala-compress/OE 3 (1)_3_11zon.jpg'
import i7 from '../images/Ornaments/oppana idamala-compress/OE 4_4_11zon.jpg'
import i8 from '../images/Ornaments/oppana idamala-compress/OE 5 (2)_5_11zon.jpg'
import i9 from '../images/Ornaments/oppana idamala-compress/OE 6 (2)_6_11zon.jpg'
import i10 from '../images/Ornaments/oppana idamala-compress/OE 7 (1)_1_11zon.jpg'
import i11 from '../images/Ornaments/oppana idamala-compress/OE 8 (2)_2_11zon.jpg'
import i12 from '../images/Ornaments/oppana idamala-compress/OE 9 (1)_3_11zon.jpg'
import i13 from '../images/Ornaments/oppana idamala-compress/OEM 1 (1)_1_11zon.jpg'
import i14 from '../images/Ornaments/oppana idamala-compress/OEM 2 (2)_2_11zon.jpg'
import i15 from '../images/Ornaments/oppana idamala-compress/OEM 3 (1)_3_11zon.jpg'
import i16 from '../images/Ornaments/oppana idamala-compress/OEM 4 (2)_4_11zon.jpg'
import i17 from '../images/Ornaments/oppana idamala-compress/OEM 5 (1)_5_11zon.jpg'
import i18 from '../images/Ornaments/oppana idamala-compress/OES 1_6_11zon.jpg'
import i19 from '../images/Ornaments/oppana idamala-compress/OES 2_1_11zon.jpg'
import i20 from '../images/Ornaments/oppana idamala-compress/OES 3_2_11zon.jpg'

import olm1 from '../images/Ornaments/Oppana long Manga-compress/OMB 1_11zon.jpg'
import olm2 from '../images/Ornaments/Oppana long Manga-compress/OMS 1_11zon.jpg'

import op1 from '../images/Ornaments/Oppana Properties-compress/OA (1)_1_11zon.jpg'
import op2 from '../images/Ornaments/Oppana Properties-compress/OK 1_2_11zon.jpg'
import op3 from '../images/Ornaments/Oppana Properties-compress/OK 2_3_11zon.jpg'

import pathakkam1 from '../images/Ornaments/Pathakkam-compress/L 8_11zon.jpg'
import pathakkam2 from '../images/Ornaments/Pathakkam-compress/TLP 1_11zon.jpg'

import s1 from '../images/Ornaments/Silver SeT-compress/S 1 (1)_11zon.jpg'
import s2 from '../images/Ornaments/Silver SeT-compress/S 2 (1)_11zon.jpg'
import s3 from '../images/Ornaments/Silver SeT-compress/S 3 (1)_11zon.jpg'

import st1 from '../images/Ornaments/stone set-compress/ST 1 (1)_1_11zon.jpg'
import st2 from '../images/Ornaments/stone set-compress/ST 1 (2)_2_11zon.jpg'
import st3 from '../images/Ornaments/stone set-compress/ST 2(1)_3_11zon.jpg'
import st4 from '../images/Ornaments/stone set-compress/ST 2_4_11zon.jpg'
import st5 from '../images/Ornaments/stone set-compress/ST 3 (1)_5_11zon.jpg'
import st6 from '../images/Ornaments/stone set-compress/ST 3 (6)_6_11zon.jpg'

import t1 from '../images/Ornaments/thoda-compress/TG 1_1_11zon.jpg'
import t2 from '../images/Ornaments/thoda-compress/TG 10_5_11zon.jpg'
import t3 from '../images/Ornaments/thoda-compress/TG 2_2_11zon.jpg'
import t4 from '../images/Ornaments/thoda-compress/TG 3 (2)_3_11zon.jpg'
import t5 from '../images/Ornaments/thoda-compress/TG 4_4_11zon.jpg'
import t6 from '../images/Ornaments/thoda-compress/TG 5_5_11zon.jpg'
import t7 from '../images/Ornaments/thoda-compress/TG 6_1_11zon.jpg'
import t8 from '../images/Ornaments/thoda-compress/TG 7_2_11zon.jpg'
import t9 from '../images/Ornaments/thoda-compress/TG 8_3_11zon.jpg'
import t10 from '../images/Ornaments/thoda-compress/TG 9_4_11zon.jpg'
import t11 from '../images/Ornaments/thoda-compress/TS 1 (1)_1_11zon.jpg'
import t12 from '../images/Ornaments/thoda-compress/TS 2 (2)_2_11zon.jpg'
import t13 from '../images/Ornaments/thoda-compress/TS 3_3_11zon.jpg'
import t14 from '../images/Ornaments/thoda-compress/TS 4_4_11zon.jpg'
import t15 from '../images/Ornaments/thoda-compress/TS 5_5_11zon.jpg'
import t16 from '../images/Ornaments/thoda-compress/TS 6_1_11zon.jpg'
import t17 from '../images/Ornaments/thoda-compress/TS 7_2_11zon.jpg'
import t18 from '../images/Ornaments/thoda-compress/TS 8_3_11zon.jpg'

import mko from '../images/Ornaments/margamkali-compress/Margam Kali Ornaments_11zon.jpg'
import Navbar2 from '../components/Navbar2'
import Footer2 from '../components/Footer2'


function Ornaments() {

  const [showMore, setShowMore] = useState(false);

  const [selectedType, setSelectedType] = useState('blackmetal');
  const handleTypeClick = (type) => {
    setSelectedType(type);
  };
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const home = () => {
    navigate('/');
  };


  return (
    <div className='min-h-screen flex flex-col'>
      <Navbar2 />
      <div className='min-h-screen flex flex-col w-full h-auto lg:px-12 px-2  py-8 gap-8 items-start justify-start'>
        <div className=''><span className='text-[#828282] text-lg font-[500] font-[display2] cursor-pointer' onClick={home}>Home</span> {">"}  <span className='text-lg font-[500] font-[display2]'>Ornaments</span></div>
        <div className='w-full flex flex-row'>
          {/* <div className='w-[10%]  flex justify-start '>
                <div className='w-[60%] h-[33px] border-2 border-[#9D9494] rounded-[5px] flex flex-row justify-center items-center gap-2'>
                   <img src={filter} alt='filter'/>
                   <span className='text-lg font-[500] font-[display]'>Filter</span>
                </div>
            </div> */}
          {/* border-2 border-[#C30000] p-2 rounded-[20px] */}
          <div className='w-[100%]  justify-center items-center grid lg:grid-cols-5 grid-cols-2 lg:gap-6 gap-3'>
            <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer  ${selectedType === 'blackmetal' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('blackmetal')}>BLACK METAL</span>
            <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'chettinad' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('chettinad')}>CHETTINAD </span>
            <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'classical' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('classical')}>CLASSICAL </span>
            <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'folk' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('folk')}>FOLK </span>
            <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'goldenjimiki' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('goldenjimiki')}>GOLDEN JIMIKI</span>
            {showMore && (
              <>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'goldenset' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('goldenset')}> GOLDEN SET</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'groupheadset' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('groupheadset')}>GROUP HEADSET</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'ilakathali' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('ilakathali')}>ILAKATHALI</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'kashimala' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('kashimala')}>KASHIMALA</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'licechoker' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('licechoker')}>LICE CHOKER</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer  ${selectedType === 'longchaingold' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('longchaingold')}>LONG CHAIN GOLD</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'manavatty' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('manavatty')}>MANAVATTY </span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'mavelikrishnan' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('mavelikrishnan')}>MAVELI KRISHNAN </span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'neckless' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('neckless')}>NECKLACE</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'oppanaidamala' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('oppanaidamala')}>OPPANA IDAMALA</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'oppanalongmala' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('oppanalongmala')}>OPPANA LONG MANGA</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'oppanaproperties' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('oppanaproperties')}>OPPANA PROPERTIES</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'pathakkam' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('pathakkam')}>PATHAKKAM</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'silverset' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('silverset')}>SILVER SET</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'stoneset' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('stoneset')}>STONE SET</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'thoda' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('thoda')}>THODA</span>
                <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'margamkali' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('margamkali')}>MARGAMKALI </span>
              </>
            )}
            {/* Toggle button */}
            <button
              onClick={() => setShowMore(!showMore)}
              className="text-[#C30000] font-[500] mt-2"
            >
              {showMore ? 'See Less' : 'See More'}
            </button>
          </div>
        </div>
        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'blackmetal' ? 'grid' : 'none' }}>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>B1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>B2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>B3</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>B4 1</div>
          </div>



          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>B4 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={f6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>B5</div>
          </div>

        </div>



        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'chettinad' ? 'grid' : 'none' }}>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHT 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHT 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHT 3</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHT 4.5</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHT 4</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTA 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTC 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTJ 1</div>
          </div>


          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTJ 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTJ 3</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c11} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTJ 4</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c12} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTJ 5</div>
          </div>


          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c13} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTJ 6</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c14} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTJ 7</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c15} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTJ 8</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c16} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTJ 9</div>
          </div>



          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c17} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTN 10</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c18} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTN 11</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c19} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTN 12</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c20} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTN 13</div>
          </div>


          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c21} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTN 5</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c22} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTN 7</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c23} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTN 8</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={c24} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CHTN 9</div>
          </div>


        </div>



        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'classical' ? 'grid' : 'none' }}>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 7</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 8</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 9</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 11</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 13</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO14 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO14 2</div>
          </div>


          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 15</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl11} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO16 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl12} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO16 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl13} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 17</div>
          </div>


          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl14} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 19</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl15} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 20</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl16} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO21 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl17} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO21 2</div>
          </div>



          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl18} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 23</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl19} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 24</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl20} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 25</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl21} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 26</div>
          </div>


          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl22} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 27</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl23} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 28</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl24} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 29</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl25} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 30</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl26} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 31</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={cl27} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CO 33</div>
          </div>


        </div>




        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'folk' ? 'grid' : 'none' }}>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>FO 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>FO 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>FO 3</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>FO 4</div>
          </div>



          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>FO 5</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>FO 6</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TF 9</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TF 10</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={jlp10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TF 11</div>
          </div>

        </div>


        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'goldenjimiki' ? 'grid' : 'none' }}>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>J 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>J 3</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>J 4</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>J 5</div>
          </div>



          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>J 7</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>J 9</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>JB 10</div>
          </div>   <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>JB 12</div>
          </div>   <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>JB13 1</div>
          </div>   <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>JB13 2</div>
          </div>   <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p11} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>JC 1</div>
          </div>   <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p12} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>JS 5</div>
          </div>   <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={p13} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>JS 6</div>
          </div>

        </div>


        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'goldenset' ? 'grid' : 'none' }}>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mm1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>G 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mm2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>G1 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mm3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>G 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mm4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>G 3</div>
          </div>

        </div>

        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'groupheadset' ? 'grid' : 'none' }}>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ptlp1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>GH 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ptlp2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>SH 1</div>
          </div>


        </div>




        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'ilakathali' ? 'grid' : 'none' }}>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={gs1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>ILT 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={gs2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>ILT 2</div>
          </div>


        </div>





        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'kashimala' ? 'grid' : 'none' }}>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ksh1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>L 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ksh2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>L 3</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ksh3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>L 4</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ksh4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>L 5</div>
          </div>



          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ksh5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>L 6</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={ksh6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>L 7</div>
          </div>


        </div>





        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'licechoker' ? 'grid' : 'none' }}>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CH 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CH 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CH 3</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CH 4</div>
          </div>



          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CH 5</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CH 6</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={lpt7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>CH 7</div>
          </div>

        </div>

        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'longchaingold' ? 'grid' : 'none' }}>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={l9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>L 9</div>
          </div>
        </div>



        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'manavatty' ? 'grid' : 'none' }}>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB1 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB1 2</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB1 3</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB3 1</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB3 2</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB 4</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB 5</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MB 6</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MS 1</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MS 2 </div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo11} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MSH 1 </div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo12} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>VMB 2</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo13} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>VMM1 1</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo14} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>VMM1 2 </div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mo15} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>VMS 1</div>
          </div>

        </div>





        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'mavelikrishnan' ? 'grid' : 'none' }}>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mk1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KO 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mk2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KO 11 BIG</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mk3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KO 11 SMALL</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mk4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KO 11</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mk5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KO13 1</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mk6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KO13 2</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mk7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KO 14 </div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mk8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KO 15</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mk9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KO 2</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mk10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KO 3 </div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mk11} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KO 4</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mk12} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KO 5</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mk13} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>KO 9</div>
          </div>


        </div>






        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'neckless' ? 'grid' : 'none' }}>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MO 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 1</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 10</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 11</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 12</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 13</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 14 </div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 15</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 16</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 17 </div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n11} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 18</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n12} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 19</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n13} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n14} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 20 </div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n15} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 21</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n16} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 22</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n17} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 23</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n18} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 24 </div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n19} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 25</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n20} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 26</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n21} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 27</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n22} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 28</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n23} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 3</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n24} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 4</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n25} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 5</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n26} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 6</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n27} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 7</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n28} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 8</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={n29} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>N 9</div>
          </div>



        </div>






        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'oppanaidamala' ? 'grid' : 'none' }}>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OE 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OE 10</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OE 11</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OE 12</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OE 2</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OE 3</div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OE 4 </div>
          </div> <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OE 5</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OE 6</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OE 7 </div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i11} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OE 8</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i12} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OE 9</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i13} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OEM 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i14} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OEM 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i15} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OEM 3</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i16} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OEM 4</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i17} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OEM 5</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i18} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OES 1 </div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i19} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OES 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={i20} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OES 3</div>
          </div>

        </div>



        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'oppanalongmala' ? 'grid' : 'none' }}>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={olm1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OMB 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={olm2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OMS 1</div>
          </div>

        </div>



        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'oppanaproperties' ? 'grid' : 'none' }}>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={op1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OA 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={op2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OK 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={op3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OK 2</div>
          </div>

        </div>


        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'pathakkam' ? 'grid' : 'none' }}>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={pathakkam1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>L 8</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={pathakkam2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TLP 1</div>
          </div>

        </div>




        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'silverset' ? 'grid' : 'none' }}>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={s1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>S 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={s2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>S 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={s3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>S 3</div>
          </div>

        </div>




        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'stoneset' ? 'grid' : 'none' }}>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={st1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>ST1 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={st2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>ST1 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={st3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>ST2 1</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={st4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>ST2 2</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={st5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>ST3 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={st6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>ST3 2</div>
          </div>

        </div>




        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'thoda' ? 'grid' : 'none' }}>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t1} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TG 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t2} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TG 10</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t3} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TG 2</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t4} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TG 3</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t5} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TG 4</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t6} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TG 5</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t7} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TG 6</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t8} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TG 7</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t9} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TG 8</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t10} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TG 9</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t11} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TS 1</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t12} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TS 2</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t13} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TS 3</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t14} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TS 4</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t15} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TS 5</div>
          </div>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t16} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TS 6</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t17} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TS 7</div>
          </div>
          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={t18} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>TS 8</div>
          </div>

        </div>


        <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'margamkali' ? 'grid' : 'none' }}>

          <div className='lg:h-[400px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
            <div className='w-full h-[90%] '>
              <img src={mko} loading='lazy' alt='hsop1' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MARGAMKALI ORNAMENTS</div>
          </div>
        </div>



      </div>
      <Footer2 />
    </div>
  )
}

export default Ornaments
