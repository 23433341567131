import React from 'react'
import logo from '../images/footer/logofooter.png'
import { useNavigate } from 'react-router-dom';

import location from '../images/footer/location.png'
import mail from '../images/footer/mail.png'
import call from '../images/footer/call.png'


function Footer({ scrollToAbout, scrollToProduct }) {

    const navigate = useNavigate()


    const contact = () => {
        navigate('/contact');
    };

    const home = () => {
        navigate('/');
    };

    const terms = () => {
        navigate('/terms');
    };


    const privacypage = () => {
        navigate('/privacy');
    };


    return (
        <div className='flex flex-col w-full h-auto'>
            <div className='w-full h-[auto] py-12 bg-[#3D3B3B] flex lg:flex-row flex-col lg:px-12 px-4 lg:gap-0 gap-12'>
                <div className='lg:w-[40%] w-[100%] h-full flex flex-col justify-center  gap-5'>
                    <div className='flex flex-row items-center gap-5'>
                        <img src={logo} alt='logo' />
                        <span className='text-l font-[display2] font-[500] text-[white]'>KAIRALI DANCE COLLECTION</span>
                    </div>
                    <span className='text-sm font-[500] font-[display2] text-[white] text-left'>Affordable Dance Costume Rentals for All<br /> Your Performance Needs</span>
                    <div className='flex flex-row items-center gap-5'>
                        <img src={location} alt='locatioon' />
                        <span className='text-sm font-[500] font-[display2] text-[white] '>Perintalmanna, Malappuram - <br />679322 (Near Traffic Junction)</span>
                    </div>
                    <div className='flex flex-row items-center gap-5'>
                        <img src={mail} alt='locatioon' />
                        <span className='text-sm font-[500] font-[display2] text-[white] '>kairalicollectionspmna@gmail.com</span>
                    </div>
                    <div className='flex flex-row items-center gap-5'>
                        <img src={call} alt='locatioon' />
                        <span className='text-sm font-[500] font-[display2] text-[white] '>+91 8078479759 <br/>+91 9544870571</span>
                    </div>
                </div>
                <div className='lg:w-[30%] w-[100%] h-full flex  flex-col justify-center  gap-8'>
                    <div className='flex flex-row items-center gap-5'>

                        <span className='text-sm font-[display2] font-[500] text-[white]'>COMPANY</span>
                    </div>
                    <span className='text-sm font-[400] font-[display2] hover:text-blue-500 text-[white] text-left cursor-pointer' onClick={home}>Home</span>
                    <span className='text-sm font-[400] font-[display2] hover:text-blue-500 text-[white] text-left cursor-pointer' onClick={scrollToAbout}>About</span>
                    <span className='text-sm font-[400] font-[display2] hover:text-blue-500 text-[white] text-left cursor-pointer' onClick={scrollToProduct}>Product</span>
                    <span className='text-sm font-[400] font-[display2] hover:text-blue-500 text-[white] text-left cursor-pointer' onClick={contact}>Contact</span>


                </div>
                <div className='lg:w-[40%] w-[100%] h-full flex   flex-col justify-center  gap-8'>
                    <div className='flex flex-row items-center gap-5'>

                        <span className='text-sm font-[display2] font-[500] text-[white]'>QUESTION US</span>

                    </div>
                    <span className='text-sm font-[400] font-[display2] text-[white] text-left'>Subscribe to stay tuned for new web
                        design and latest updates.<br /> Let’s do it!</span>

                    <div className='w-full h-[48px] flex flex-row'>
                        <div className='w-[70%] h-full '>
                            <input className='w-[100%] h-[100%] px-3 text-base font-[500] font-[display2] rounded-l-[5px]' placeholder='Enter you mail here..' />
                        </div>
                        <div className='w-[30%] h-full bg-[black] text-white flex justify-center items-center text-base font-[500] font-[display2] cursor-pointer'>Subscibe</div>
                    </div>
                </div>
            </div>
            <div className='w-full h-[40px]  flex flex-row lg:px-4 px-1 '>
                <div className='w-[50%] text-start h-full flex  items-center lg:text-sm text-xs font-[400] font-[display2]'>© SherSoft Software Company</div>
                <div className='w-[50%] h-full flex  items-center justify-end flex-row gap-8'>
                    <span className='lg:text-sm text-xs font-[400] font-[display2] cursor-pointer' onClick={terms}>Terms & Conditions</span>
                    <span className='lg:text-sm text-xs font-[400] font-[display2] cursor-pointer' onClick={privacypage}>Privacy Policy</span>
                </div>
            </div>
        </div>
    )
}

export default Footer
