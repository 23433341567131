import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import hsop1 from '../images/oppanahs-compress/HSOP 1_1_11zon.jpg'
import hsop2 from '../images/oppanahs-compress/HSOP 2_2_11zon.jpg'
import hsop3 from '../images/oppanahs-compress/HSOP 3_3_11zon.jpg'
import hsop4 from '../images/oppanahs-compress/HSOP 4_4_11zon.jpg'
import hsop5 from '../images/oppanahs-compress/HSOP 5_5_11zon.jpg'
import hsop6 from '../images/oppanahs-compress/HSOP 6_1_11zon.jpg'
import hsop7 from '../images/oppanahs-compress/HSOP 7_1_11zon.jpg'
import hsop8 from '../images/oppanahs-compress/HSOP 8_2_11zon.jpg'
import hsop9 from '../images/oppanahs-compress/HSOP 9_3_11zon.jpg'
import hsop11 from '../images/oppanahs-compress/HSOP 11_4_11zon.jpg'
import hsop12 from '../images/oppanahs-compress/HSOP 12_5_11zon.jpg'
import hsop13 from '../images/oppanahs-compress/HSOP 13_6_11zon.jpg'
import hsop14 from '../images/oppanahs-compress/HSOP 14_1_11zon.jpg'
import hsop15 from '../images/oppanahs-compress/HSOP 15_2_11zon.jpg'
import hsop16 from '../images/oppanahs-compress/HSOP 16_3_11zon.jpg'
// import filter from '../images/oppanahs/Slider.png'

import kgmb1 from '../images/oppanalp-compress/LPOP 1_1_11zon.jpg'
import lpb2 from '../images/oppanalp-compress/LPOP 2_2_11zon.jpg'
import lpb35 from '../images/oppanalp-compress/LPOP 3_3_11zon.jpg'
import lpb48 from '../images/oppanalp-compress/LPOP 4_4_11zon.jpg'
import lpb6 from '../images/oppanalp-compress/LPOP 6_5_11zon.jpg'
import lpb7910 from '../images/oppanalp-compress/LPOP 7_1_11zon.jpg'
import lpmb18 from '../images/oppanalp-compress/LPOP 8_2_11zon.jpg'
import lpmb19 from '../images/oppanalp-compress/LPOP 9_3_11zon.jpg'


import upop1 from '../images/oppanaup-compress/UPOP 1_1_11zon.jpg'
import upop2 from '../images/oppanaup-compress/UPOP 2_2_11zon.jpg'
import upop3 from '../images/oppanaup-compress/UPOP 3_3_11zon.jpg'
import upop4 from '../images/oppanaup-compress/UPOP 4_4_11zon.jpg'
import upop5 from '../images/oppanaup-compress/UPOP 5_5_11zon.jpg'

import ohmb from '../images/oppanamund-compress/OHM B_1_11zon.jpg'
import ohmg from '../images/oppanamund-compress/OHM G_2_11zon.jpg'
import ohmr from '../images/oppanamund-compress/OHM R_3_11zon.jpg'
import okm from '../images/oppanamund-compress/OKM_4_11zon.jpg'
import olm from '../images/oppanamund-compress/OLM_5_11zon.jpg'
import oum from '../images/oppanamund-compress/OUM_6_11zon.jpg'

import hsopt3 from '../images/oppanathattamhsandup-compress/HSOPT 3_1_11zon.jpg'
import hsopt4 from '../images/oppanathattamhsandup-compress/HSOPT 4_2_11zon.jpg'
import hsopt5 from '../images/oppanathattamhsandup-compress/HSOPT 5_3_11zon.jpg'
import hsopt6 from '../images/oppanathattamhsandup-compress/HSOPT 6 (2)_4_11zon.jpg'
import hsopt7 from '../images/oppanathattamhsandup-compress/HSOPT 7_5_11zon.jpg'
import hsopt8 from '../images/oppanathattamhsandup-compress/HSOPT 8 (4)_1_11zon.jpg'
import hsopt9 from '../images/oppanathattamhsandup-compress/HSOPT 9 (1)_2_11zon.jpg'
import hsopt10 from '../images/oppanathattamhsandup-compress/HSOPT 10 (1)_3_11zon.jpg'
import hsopt11 from '../images/oppanathattamhsandup-compress/HSOPT 11 (1)_4_11zon.jpg'
import hsopt12 from '../images/oppanathattamhsandup-compress/HSOPT 12_5_11zon.jpg'
import hsopt13 from '../images/oppanathattamhsandup-compress/HSOPT 13 (2)_1_11zon.jpg'
import hsopt14 from '../images/oppanathattamhsandup-compress/HSOPT 14 (2)_2_11zon.jpg'
import hsopt15 from '../images/oppanathattamhsandup-compress/HSOPT 15_3_11zon.jpg'
import hsopt16 from '../images/oppanathattamhsandup-compress/HSOPT 16 (2)_4_11zon.jpg'
import hsopt17 from '../images/oppanathattamhsandup-compress/HSOPT 17_1_11zon.jpg'
import hsopt18 from '../images/oppanathattamhsandup-compress/HSOPT 18_2_11zon.jpg'
import hsopt19 from '../images/oppanathattamhsandup-compress/HSOPT 19_3_11zon.jpg'
import hsopt20 from '../images/oppanathattamhsandup-compress/HSOPT 20_4_11zon.jpg'

import lpot3 from '../images/oppanathattamlp-compress/LPOT 3_1_11zon.jpg'
import lpot5 from '../images/oppanathattamlp-compress/LPOT 5_2_11zon.jpg'
import lpot6 from '../images/oppanathattamlp-compress/LPOT 6_3_11zon.jpg'
import lpot7 from '../images/oppanathattamlp-compress/LPOT 7_4_11zon.jpg'
import lpot8 from '../images/oppanathattamlp-compress/LPOT 8_5_11zon.jpg'
import lpot9 from '../images/oppanathattamlp-compress/LPOT 9_6_11zon.jpg'
import lpot10 from '../images/oppanathattamlp-compress/LPOT 10_1_11zon.jpg'
import lpot11 from '../images/oppanathattamlp-compress/LPOT 11 (1)_2_11zon.jpg'
import Navbar2 from '../components/Navbar2'
import Footer2 from '../components/Footer2'




function Oppana() {

  const [selectedType, setSelectedType] = useState('oppanablousehs');
  const handleTypeClick = (type) => {
    setSelectedType(type);
  };
  const navigate = useNavigate()

  useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  

  const dancecostume = () => {
      navigate('/dancecostume');
    };


  return (
    <div className='min-h-screen flex flex-col'>
      <Navbar2/>
      <div className='flex flex-col w-full h-auto lg:px-12 px-2  py-8 gap-8 items-start justify-start'>
          <div  className=''><span className='text-[#828282] text-lg font-[500] font-[display2] cursor-pointer' onClick={dancecostume}>Dance Costume</span> {">"}  <span className='text-lg font-[500] font-[display2]'>Oppana</span></div>
          <div className='w-full flex flex-row'>
            {/* <div className='w-[10%]  flex justify-start '>
                <div className='w-[60%] h-[33px] border-2 border-[#9D9494] rounded-[5px] flex flex-row justify-center items-center gap-2'>
                   <img src={filter} alt='filter'/>
                   <span className='text-lg font-[500] font-[display2]'>Filter</span>
                </div>
            </div> */}
            {/* border-2 border-[#C30000] p-2 rounded-[20px] */}
            <div className='w-[100%]  justify-center items-center flex md:flex-row flex-col lg:gap-10 gap-3'>
                 <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'oppanablousehs' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('oppanablousehs')}>BLOUSE HS</span>
                 <span  className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'oppanablouselp' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('oppanablouselp')}>BLOUSE LP</span>
                 <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'oppanablouseup' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('oppanablouseup')}>BLOUSE UP</span>
                 <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'oppanamund' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('oppanamund')}>MUND</span>
                 <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'oppanathattamhsandup' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('oppanathattamhsandup')}>THATTAM HS & UP</span>
                 <span className={`lg:text-base text-sm font-[500] font-[display2] cursor-pointer ${selectedType === 'oppanathattamlp' ? 'border-2 border-[#C30000] p-2 rounded-[20px]' : ''}`} onClick={() => handleTypeClick('oppanathattamlp')}>THATTAM LP</span>

            </div>
          </div>
          <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'oppanablousehs' ? 'grid' : 'none' }}>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop1} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 1</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop2} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 2</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop3} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 3</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop4} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 4</div>
               </div>
               


               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop5} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 5</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop6} loading='lazy'  alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 6</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop7} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 7</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop8} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 8</div>
               </div>

               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop9} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 9</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop11} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 11</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop12} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 12</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop13} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 13</div>
               </div>

               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop14} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 14</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop15} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 15</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsop16} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOP 16</div>
               </div>

              
          </div>



          <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'oppanablouselp' ? 'grid' : 'none' }}>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={kgmb1} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOP 1</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpb2} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOP 2</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpb35} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOP 3</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpb48} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOP 4</div>
               </div>
               


               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpb6} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOP 6</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpb7910} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOP 7</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpmb18} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOP 8</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpmb19} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOP 9</div>
               </div>
            </div>



          <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'oppanablouseup' ? 'grid' : 'none' }}>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={upop1} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>UPOP 1</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={upop2} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>UPOP 2</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={upop3} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>UPOP 3</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={upop4} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>UPOP 4</div>
               </div>
               


               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={upop5} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>UPOP 5</div>
               </div>
             
           </div>




           <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'oppanamund' ? 'grid' : 'none' }}>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={ohmb} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OHM B</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={ohmg} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OHM G</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={ohmr} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OHM R</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={okm} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OKM</div>
               </div>
               


               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={olm} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OLM</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={oum} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>OUM</div>
               </div>
             
           </div>







           <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'oppanathattamhsandup' ? 'grid' : 'none' }}>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt3} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 3</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt4} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 4</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt5} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 5</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt6} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 6</div>
               </div>
               


               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt7} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 7</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt8} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 8</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt9} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 9</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt10} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 10</div>
               </div>


               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt11} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 11</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt12} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 12</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt13} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 13</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt14} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 14</div>
               </div>


               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt15} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 15</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt16} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 16</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt17} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 17</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt18} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 18</div>
               </div>

               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt19} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 19</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={hsopt20} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>HSOPT 20</div>
               </div>
              
           </div>





           <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' style={{ display: selectedType === 'oppanathattamlp' ? 'grid' : 'none' }}>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpot3} loading='lazy'  alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOT 3</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpot5} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOT 5</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpot6} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOT 6</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpot7} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOT 7</div>
               </div>
               


               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpot8}  loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOT 8</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpot9} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOT 9</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpot10} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOT 10</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={lpot11} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>LPOT 11</div>
               </div>
             
           </div>




     







      </div>
      <Footer2/>
    </div>
  )
}

export default Oppana
