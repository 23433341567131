import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import mh1234 from '../images/mohiniyattam-compress/MH 1.2.3.4 (2)_1_11zon.jpg'
import mh6789 from '../images/mohiniyattam-compress/MH 6.7.8.9 (1)_2_11zon.jpg'
import Footer2 from '../components/Footer2'
import Navbar2 from '../components/Navbar2'

function Mohiniyattam() {

 
  const navigate = useNavigate()

  useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  

  const dancecostume = () => {
      navigate('/dancecostume');
    };


  return (
    <div className='min-h-screen flex flex-col'>
      <Navbar2/>
      <div className='min-h-screen flex flex-col w-full h-auto lg:px-12 px-2  py-8 gap-8 items-start justify-start'>
          <div  className=''><span className='text-[#828282] text-lg font-[500] font-[display2] cursor-pointer' onClick={dancecostume}>Dance Costume</span> {">"}  <span className='text-lg font-[500] font-[display2]'>Mohiniyattam</span></div>
         
          <div className='grid lg:grid-cols-4 grid-cols-2 gap-3 h-auto w-full ' >
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={mh1234} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MH 1.2.3.4</div>
               </div>
               <div className='lg:h-[450px] h-[250px] md:h-[400px] sm:h-[350px]  flex flex-col'>
                  <div className='w-full h-[90%] '>
                    <img src={mh6789} loading='lazy' alt='hsop1' style={{width:'100%',height:'100%'}}/>
                  </div>
                  <div className='w-full h-[10%]  flex justify-center items-center lg:text-xl md:text-lg text-xs font-[600] font-[display2]'>MH 6.7.8.9</div>
               </div>
          </div>
      </div>
      <Footer2/>
    </div>
  )
}

export default Mohiniyattam
