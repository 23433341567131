import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import diamond from '../images/dancecostume/diamond.png'
import Navbar2 from '../components/Navbar2'


function DanceCostume() {

    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const home = () => {
        navigate('/');
    };

    const oppana = () => {
        navigate('/oppana');
    };

    const Mohiniyattam = () => {
        navigate('/mohiniyattam');
    };


    const Thiruvathira = () => {
        navigate('/thiruvathira');
    };

    const manavatty = () => {
        navigate('/manavatty');
    };


    const folk = () => {
        navigate('/folk');
    };

    const keralanadanam = () => {
        navigate('/keralanadanam');
    };

    const cinematic = () => {
        navigate('/cinematic');
    };

    const group = () => {
        navigate('/group');
    };

    const Classical = () => {
        navigate('/bharathanatyam');
    };

    const kuchupudi = () => {
        navigate('/kuchuppudi');
    };

    const semiclassical = () => {
        navigate('/semiclassical');
    };

    const margamkali = () => {
        navigate('/margamkali');
    };


    return (
        <div className='min-h-screen flex flex-col'>
            <Navbar2 />
            <div className='flex flex-col w-full h-auto lg:px-12 px-4  py-8 gap-8 items-start justify-start'>
                <div className=''><span className='text-[#828282] text-lg font-[500] font-[display2] cursor-pointer' onClick={home}>Home</span> {">"}  <span className='text-lg font-[500] font-[display2]'>Dance Costume</span></div>
                <div className='grid lg:grid-cols-4 grid-cols-1 gap-3 h-auto w-full '>
                    <div className='h-[115px] flex flex-col  cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={Classical}>

                        

                        <div className='h-full w-full  flex justify-center items-center  bg-[#F4E7D4] rounded-[10px]'>
                            <div className=' w-full  flex flex-row justify-center items-center gap-5 '>
                                <img src={diamond} alt='bn' />
                                <span className='text-2xl font-[400] font-[display2]'>Bharatanatyam</span>
                                <img src={diamond} alt='bn' />
                            </div>

                        </div>
                    </div>
                    <div className='h-[115px] flex flex-col  cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={kuchupudi}>


                        <div className='h-full w-full  flex justify-center items-center  bg-[#F4E7D4] rounded-[10px] '>
                            <div className=' w-full flex flex-row justify-center items-center gap-5 '>
                                <img src={diamond} alt='bn' />
                                <span className='text-2xl font-[400] font-[display2]'>Kuchipudi</span>
                                <img src={diamond} alt='bn' />
                            </div>

                        </div>
                    </div>
                    <div className='h-[115px] flex flex-col  cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out'>


                        <div className='h-full w-full  flex justify-center items-center  bg-[#F4E7D4] rounded-[10px] ' onClick={Mohiniyattam}>
                            <div className=' w-full  flex flex-row justify-center items-center gap-5 '>
                                <img src={diamond} alt='bn' />
                                <span className='text-2xl font-[400] font-[display2]'>Mohiniattam</span>
                                <img src={diamond} alt='bn' />
                            </div>

                        </div>
                    </div>
                    <div className='h-[115px] flex flex-col  cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={oppana}>


                        <div className='h-full w-full  flex justify-center items-center  bg-[#F4E7D4] rounded-[10px] '>
                            <div className=' w-full  flex flex-row justify-center items-center gap-5 '>
                                <img src={diamond} alt='bn' />
                                <span className='text-2xl font-[400] font-[display2]'>Oppana</span>
                                <img src={diamond} alt='bn' />
                            </div>

                        </div>
                    </div>
                    <div className='h-[115px] flex flex-col  cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={Thiruvathira}>


                        <div className='h-full w-full  flex justify-center items-center  bg-[#F4E7D4] rounded-[10px] '>
                            <div className=' w-full  flex flex-row justify-center items-center gap-5 '>
                                <img src={diamond} alt='bn' />
                                <span className='text-2xl font-[400] font-[display2]'>Thiruvathira</span>
                                <img src={diamond} alt='bn' />
                            </div>

                        </div>
                    </div>
                    <div className='h-[115px] flex flex-col  cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={manavatty}>


                        <div className='h-full w-full  flex justify-center items-center  bg-[#F4E7D4] rounded-[10px] '>
                            <div className=' w-full flex flex-row justify-center items-center gap-5 '>
                                <img src={diamond} alt='bn' />
                                <span className='text-2xl font-[400] font-[display2]'>Oppana Manavatty</span>
                                <img src={diamond} alt='bn' />
                            </div>

                        </div>
                    </div>
                    <div className='h-[115px] flex flex-col  cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={folk}>


                        <div className='h-full w-full  flex justify-center items-center  bg-[#F4E7D4] rounded-[10px] '>
                            <div className=' w-full flex flex-row justify-center items-center gap-5 '>
                                <img src={diamond} alt='bn' />
                                <span className='text-2xl font-[400] font-[display2]'>Folk Dance</span>
                                <img src={diamond} alt='bn' />
                            </div>

                        </div>
                    </div>
                    <div className='h-[115px] flex flex-col  cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={cinematic}>


                        <div className='h-full w-full  flex justify-center items-center  bg-[#F4E7D4] rounded-[10px] '>
                            <div className=' w-full  flex flex-row justify-center items-center gap-5 '>
                                <img src={diamond} alt='bn' />
                                <span className='text-2xl font-[400] font-[display2]'>Cinematic Dance</span>
                                <img src={diamond} alt='bn' />
                            </div>

                        </div>
                    </div>
                    <div className='h-[115px] flex flex-col  cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={group}>


                        <div className='h-full w-full  flex justify-center items-center  bg-[#F4E7D4] rounded-[10px] '>
                            <div className=' w-full  flex flex-row justify-center items-center gap-5 '>
                                <img src={diamond} alt='bn' />
                                <span className='text-2xl font-[400] font-[display2]'>Group Dance</span>
                                <img src={diamond} alt='bn' />
                            </div>

                        </div>
                    </div>
                    <div className='h-[115px] flex flex-col  cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={keralanadanam}>


                        <div className='h-full w-full  flex justify-center items-center  bg-[#F4E7D4] rounded-[10px] '>
                            <div className=' w-full  flex flex-row justify-center items-center gap-5 '>
                                <img src={diamond} alt='bn' />
                                <span className='text-2xl font-[400] font-[display2]'>Kerala Nadanam</span>
                                <img src={diamond} alt='bn' />
                            </div>

                        </div>
                    </div>

                    <div className='h-[115px] flex flex-col  cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={semiclassical}>


                        <div className='h-full w-full  flex justify-center items-center  bg-[#F4E7D4] rounded-[10px] '>
                            <div className=' w-full  flex flex-row justify-center items-center gap-5 '>
                                <img src={diamond} alt='bn' />
                                <span className='text-2xl font-[400] font-[display2]'>Semi Classical</span>
                                <img src={diamond} alt='bn' />
                            </div>

                        </div>
                    </div>
                    <div className='h-[115px] flex flex-col  cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out' onClick={margamkali}>


                        <div className='h-full w-full  flex justify-center items-center  bg-[#F4E7D4] rounded-[10px] '>
                            <div className=' w-full  flex flex-row justify-center items-center gap-5 '>
                                <img src={diamond} alt='bn' />
                                <span className='text-2xl font-[400] font-[display2]'>Margamkali</span>
                                <img src={diamond} alt='bn' />
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default DanceCostume
