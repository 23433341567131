import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Navbar2 from '../components/Navbar2';
import Footer2 from '../components/Footer2';

function Privacy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='min-h-screen flex flex-col'>
            <Navbar2 />
            <div className='flex flex-col  lg:px-12 px-2 py-10 gap-10'>
                <div className='w-full flex justify-center items-center font-[600] lg:text-4xl text-2xl text-[display2]'>Privacy Policy</div>
                <div className='flex flex-col w-full text-left gap-6'>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>At Kairali Dance Collections , we value the
                        privacy of our customers and are committed to protecting your personal information.
                        This Privacy Policy outlines how we collect, use, disclose, and protect your personal data when you visit our
                        website or use our services.</span>

                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>1. Information We Collect</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>We collect various types of information
                        when you visit our website, place an order, or interact with us, including:</span>

                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>1.1 Personal Information</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Name</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Email address</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Shipping and billing address</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Phone number</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Payment information (e.g., credit card details)</span>


                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>1.2 Non Personal Information</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Browser type and version</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; IP address</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Device information</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Cookies and usage data
                        (e.g., pages visited, time spent on the site)</span>



                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>1.3 Payment Information</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>We use third-party payment processors to handle payments.
                        We do not store or process
                        your credit card information directly. Payment information is
                        collected, stored, and processed by these providers under their privacy policies.</span>



                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>2. How We Use Your Information</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>The information we collect is used for
                         the following purposes:</span>


                    <span className='lg:text-3xl text-xl font-[400] font-[display2]'>2.1 To Provide Services</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Processing your orders, including shipping and returns.</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Managing your account and rental history.</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Communicating with you regarding your orders or
                         customer support inquiries.</span>


                         <span className='lg:text-3xl text-xl font-[400] font-[display2]'>2.2 For Marketing and Promotional Purposes</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; To send you promotional offers, newsletters, 
                        or marketing messages if you have opted in.</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; You can opt-out of marketing communications
                         at any time by clicking the unsubscribe link in our emails or contacting us directly.</span>
                      


                         <span className='lg:text-3xl text-xl font-[400] font-[display2]'>2.3 To Improve Our Website and Services</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Analyzing how users interact with our website to 
                        improve its functionality and the overall user experience.</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>
                        <span className='font-[900] text-4xl '>.</span>&nbsp; Using cookies to remember your preferences
                         and to provide tailored recommendations.</span>
                      


                         <span className='lg:text-3xl text-xl font-[400] font-[display2]'>3. Sharing Your Information</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>We do not sell, trade, or rent your personal 
                        information to third parties. 
                        However, we may share your information with trusted third parties under the following circumstances:</span>


                        <span className='lg:text-3xl text-xl font-[400] font-[display2]'>3.1 Service Providers</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>We may share your data with third-party 
                        service providers
                         to assist with website operations, payment processing, shipping, and customer service.</span>

                        <span className='lg:text-3xl text-xl font-[400] font-[display2]'>3.2 Legal Requirements</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>We may disclose your personal information if required
                         by law or in response to legal processes, such as subpoenas or court orders.</span>


                        <span className='lg:text-3xl text-xl font-[400] font-[display2]'>3.3 Business Transfers</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>In the event of a merger, acquisition,
                         or sale of assets, your personal information may be transferred to the new owners or parties involved
                          in the transaction.</span>



                         <span className='lg:text-3xl text-xl font-[400] font-[display2]'>4. Security of Your Information</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>We take reasonable measures to protect
                         the security of your personal information, including the use of encryption, secure servers,
                         and other technologies. However, no system is completely secure, and we cannot guarantee the
                          absolute security of your data.</span>

                        <span className='lg:text-3xl text-xl font-[400] font-[display2]'>5. Cookies and Tracking Technologies</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>We use cookies and similar tracking technologies
                         to improve your browsing experience and provide personalized services. You can control
                         the use of cookies through your browser settings, but disabling them may affect your ability
                          to use certain features of the website.</span>


                        <span className='lg:text-3xl text-xl font-[400] font-[display2]'>6. Your Rights</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>You have certain rights regarding
                         your personal information, including:</span>


                         <span className='lg:text-3xl text-xl font-[400] font-[display2]'>6.1 Access and Correction</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>You may request access to the personal information
                         we hold about you and request that we correct or update it if it is inaccurate.</span>

                         <span className='lg:text-3xl text-xl font-[400] font-[display2]'>6.2 Deletion</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>You may request that we delete your personal
                         information, subject to certain exceptions (e.g., if the data is needed for legal or billing purposes).</span>


                         <span className='lg:text-3xl text-xl font-[400] font-[display2]'>6.3 Opt-Out of Marketing</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>You may opt-out of receiving marketing
                         communications at any time by clicking the unsubscribe link in our emails or contacting us.</span>


                         <span className='lg:text-3xl text-xl font-[400] font-[display2]'>6.4 Data Portability</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>You may request that we provide
                         you with a copy of your personal data in a structured, commonly used, and machine-readable format.</span>
                         <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>To exercise any of these rights, 
                            please contact us at  <span className='font-[700] text-xl font-[display2]'>+91 9544870571</span></span>



                            <span className='lg:text-3xl text-xl font-[400] font-[display2]'>7. Third-Party Links</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>Our website may contain links to third-party
                         websites or services. We are not responsible for the
                         privacy practices or content of these external sites.
                          We encourage you to read the privacy policies of any third-party sites you visit.</span>


                         <span className='lg:text-3xl text-xl font-[400] font-[display2]'>8. Children’s Privacy</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>Our website and services are not intended
                         for children under
                         the age of 13. We do not knowingly collect personal information from children.
                         If you believe that a child has provided us with personal information,
                          please contact us, and we will take steps to delete such information.</span>

                          <span className='lg:text-3xl text-xl font-[400] font-[display2]'>9. Changes to This Privacy Policy</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>We reserve the right to update this Privacy Policy 
                        at any time. Any changes will be posted on this page, and the "Last Updated"
                         date will be revised. We encourage you to review this policy periodically
                          to stay informed about how we are protecting your information.</span>
                      



                          <span className='lg:text-3xl text-xl font-[400] font-[display2]'>10. Contact Us</span>
                    <span className='font-[400] lg:text-xl text-lg font-[display2] px-7'>If you have any questions about this
                         Privacy Policy or how we handle your personal information, please contact us at:</span>


                         <span className='font-[700] lg:text-xl text-lg font-[display2] px-7 underline'>KAIRALI DANCE COLLECTIONS</span>


                         <span className='font-[400] lg:text-xl text-lg font-[display2] px-7 '>
                            Email: &nbsp;&nbsp;&nbsp;<span className='font-[500] text-xl font-[display2]'>
                            kairalicollectionspmna@gmail.com</span>
                            </span>

                         <span className='font-[400] lg:text-xl text-lg font-[display2] px-7 '>
                         Number: &nbsp;&nbsp;&nbsp;<span className='font-[500] text-xl font-[display2]'>
                         +91 9544870571</span></span>

                         <span className='font-[400] lg:text-xl text-lg font-[display2] px-7 '>
                         Address: &nbsp;&nbsp;&nbsp;<span className='font-[500] text-xl font-[display2]'>
                         Perintalmanna, Malappuram - 679322 (Near Traffic Junction)</span>
                         </span>

                </div>
            </div>
            <Footer2 />
        </div>
    )
}

export default Privacy
