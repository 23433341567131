import './App.css';
import {Route,Routes,BrowserRouter} from 'react-router-dom';
import Home from './Pages/Home';
import Oppana from './Pages/Oppana';
import DanceCostume from './Pages/DanceCostume';
import Mohiniyattam from './Pages/Mohiniyattam';
import Contact from './Pages/Contact';
import Thiruvathira from './Pages/Thiruvathira';
import Manavatty from './Pages/Manavatty';
import Folk from './Pages/Folk';
import Keralanadanam from './Pages/Keralanadanam';
import Cinematic from './Pages/Cinematic';
import Group from './Pages/Group';
import Classical from './Pages/Classical';
import Kuchupudi from './Pages/Kuchupudi';
import SemiClassical from './Pages/SemiClassical';
import Margamkali from './Pages/Margamkali';
import DressPage from './Pages/DressPage';
import Properties from './Pages/Properties';
import Ornaments from './Pages/Ornaments';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';



function App() {
  return (
    <div className="App">
        <BrowserRouter>
              <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/contact' element={<Contact/>}/>
                <Route path='/oppana' element={<Oppana/>}/>
                <Route path='/dancecostume' element={<DanceCostume/>}/>
                <Route path='/Mohiniyattam' element={<Mohiniyattam/>}/>
                <Route path='/thiruvathira' element={<Thiruvathira/>}/>
                <Route path='/manavatty' element={<Manavatty/>}/>
                <Route path='/folk' element={<Folk/>}/>
                <Route path='/keralanadanam' element={<Keralanadanam/>}/>
                <Route path='/cinematic' element={<Cinematic/>}/>
                <Route path='/group' element={<Group/>}/>
                <Route path='/bharathanatyam' element={<Classical/>}/>
                <Route path='/kuchuppudi' element={<Kuchupudi/>}/>
                <Route path='/semiclassical' element={<SemiClassical/>}/>
                <Route path='/margamkali' element={<Margamkali/>}/>
                <Route path='/dress' element={<DressPage/>}/>
                <Route path='/properties' element={<Properties/>}/>
                <Route path='/ornaments' element={<Ornaments/>}/>
                <Route path='/terms' element={<Terms/>}/>
                <Route path='/privacy' element={<Privacy/>}/>


              </Routes>
        </BrowserRouter>
    </div>
  );                                                                                                                
}

export default App;
